import React, { useState, useMemo, useEffect } from 'react';
import { message } from "antd";
import { fetchData, removeSign } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import HoldingSummary from '../HoldingSummary';
import PieChart from '../PieChart';
import ApexChart from '../ApexChart';
import DynamicTable from '../DynamicTable';
import Analytics from '../Analytics';
import Loader from '../Loader'; // Import the loader component

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';


function CryptoCurrencyAnalytics() {

  const location = useLocation();  // Get the current location
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [selectedChart, setSelectedChart] = useState('current');
  const [analyticsValues, setAnalyticsValues] = useState({}); // State to hold asset values
  const [holdingInsights, setHoldingInsights] = useState({}); // State to hold asset values
  const [distribution, setDistribution] = useState([]);
  const [baseDailyTrend, setBaseDailyTrend] = useState({}); // State to hold asset values
  const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); // State to hold asset values
  const [referenceDailyTrend, setReferenceDailyTrend] = useState({}); // State to hold asset values
  const [referenceWeeklyTrend, setReferenceWeeklyTrend] = useState({}); // State to hold asset values
  const [profitData, setProfitData] = useState([]);
  const [lossData, setLossData] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);

  const url = apiConfig.CRYPTOCURRENCYANALYTICS;
  const method = 'GET';
  const localStorageKey = 'cryptoCurrencyAnalyticsData';
  // Fetch asset data when the component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        trackEvent('CryptoCurrency Analytics', 'request', 'Loading crypto currency analytics data');
        const data = await fetchData(url, method, localStorageKey);
        updateStateWithData(data);
        trackEvent('CryptoCurrency Analytics', 'success', 'Successfully crypto currency analytics data');
      } catch (error) {
        message.error({
          content: `Error fetching analytics data: ${error.message}`,
          duration: 3, // Duration in seconds
        });
        setError(`Error fetching analytics data: ${error.message}`);
        trackEvent('CryptoCurrency Analytics', 'error', `APi (${url}): ${error.message}`);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  loadData();
  }, [url, method, localStorageKey]);
  
  const updateStateWithData = (result) => {
    // Assuming the API returns an object with asset values
    setAnalyticsValues(result ?? {});
    setHoldingInsights(result?.holdings_insights ?? {});
    setDistribution(result?.holdings_insights?.distribution ?? []);
    setProfitData(result.holdings_summary.profit_loss.profit);
    setLossData(result.holdings_summary.profit_loss.loss);

    const baseDailyTrend = result?.historic_trend?.base_trend?.daily_data ?? [];
    setBaseDailyTrend(baseDailyTrend);

    const baseWeeklyTrend = result?.historic_trend?.base_trend?.weekly_data ?? [];
    setBaseWeeklyTrend(baseWeeklyTrend);

    // const referenceDailyTrend = result?.historic_trend?.reference_trend?.daily_data ?? [];
    // setReferenceDailyTrend(referenceDailyTrend);

    // const referenceWeeklyTrend = result?.historic_trend?.reference_trend?.weekly_data ?? [];
    // setReferenceWeeklyTrend(referenceWeeklyTrend);

    trackEvent('CryptoCurrency Analytics', 'success', 'Loaded crypto currency analytics data');
  }

  const pieDataProfitLoss = [
    ['Profit', removeSign(profitData[1]), removeSign(profitData[1])],
    ['Loss', removeSign(lossData[1]), removeSign(lossData[1])],
  ];

  const initialRows = [
    {
      id: 'row1',
      columns: [
        { id: '1', header: 'Portfolio Summary', contentId: 'holding-summary'}
      ],
    },
    {
      id: 'row2',
      columns: [
        { id: '2', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
      ],
    },
    {
      id: 'row3',
      columns: [
        { id: '3', header: 'Market Cap', contentId: 'market-cap'},
        { id: '4', header: 'Portfolio Breakdown by Crypto Currency', contentId: 'distribution'},
      ]
    },
    {
      id: 'row4',
      columns: [
        { id: '5', header: 'Unrealized Gain Breakdown by Crypto Currency Holdings', contentId: 'unrealized-gain'},
        {id: '6', header: '', contentId: ''}
      ]
    },
    {
      id: 'row5',
      columns: [
        { id: '7', header: 'Portfolio Performance by Crypto Currency Holdings', contentId: 'portfolio-performance'}
      ]
    }
  ];
  const contentMap = useMemo(() => ({
    
    'holding-summary' : <HoldingSummary data={analyticsValues.holdings_summary} pieChartData={pieDataProfitLoss}/>,
    'historic-trend' : <div className='holding-chart-container'>
                          <ApexChart baseTrend={baseDailyTrend} referenceTrend={false} baseWeeklyTrend={baseWeeklyTrend} referenceWeeklyTrend={false} referenceIndex={false}/>
                        </div>,
    ...(analyticsValues.market_cap?.length > 0 && {
      'market-cap' : <div className="checkbox-heading">
                      <div className='checkbox-wrapper adjust-height'></div>
                      <PieChart pieData={analyticsValues.market_cap} id='market-cap' width='480' height='250' />
                    </div>,
    }),
    'distribution' : <div className="checkbox-heading">
                                    {/* <h2 className='heading'>Mutual Fund Distribution</h2> */}
                                    <div className='checkbox-wrapper'>
                                      <label className='analytics-label'>
                                        <input type='radio' className='analytics-checkbox' name='chartSelector' value='current' checked={selectedChart === 'current'} onChange={() => setSelectedChart('current')}/>
                                        Current
                                      </label>
                                      <label className='analytics-label'>
                                        <input type='radio'className='analytics-checkbox'  name='chartSelector' value='invested' checked={selectedChart === 'invested'} onChange={() => setSelectedChart('invested')} />
                                        Invested
                                      </label>
                                    </div>
                                  
                                    {selectedChart === 'current' && (
                                      <PieChart pieData={distribution.curr_val} id='current' width='480' height='250' />
                                    )}
                                    {selectedChart === 'invested' && (
                                      <PieChart pieData={distribution.investment} id='invested' width='480' height='250' />
                                    )}
                                  </div>,
    ...(holdingInsights.unrealised_gain?.length > 0 && {
      'unrealized-gain' : <div className="checkbox-heading">
                          <div className='checkbox-wrapper adjust-height'></div>
                          <PieChart pieData={holdingInsights.unrealised_gain} id='unrealized-gain' width='480' height='250' />
                        </div>,
    }),
    'portfolio-performance' : <div className="piechart-wrapper">
                                <DynamicTable tableData={holdingInsights.change}/>
                              </div>
   }), [analyticsValues, holdingInsights, distribution, baseDailyTrend, baseWeeklyTrend, selectedChart, pieDataProfitLoss]);
  
  return (
    <div className='crypto-stock-analytics'>
    {isLoading ? (
        <Loader /> // Show the loader while data is loading
    ) : error ? (
      <div>{error}</div>
    ) : (
      <Analytics initialRows={initialRows} contentMap={contentMap} module={'cryptocurrency-analytics'}/>
    )}
     </div>
  );
}

export default CryptoCurrencyAnalytics;
