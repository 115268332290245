import React, {useState, useRef} from 'react'
import { Button, Form } from 'react-bootstrap';

const FileUpload = ({onFileSubmit}) => {

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [validated, setValidated] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null); // Use a ref to control the file input

    const handleFileChange = (e) => {

        const file = e.target.files[0];

        const allowedTypes = [
            'application/pdf', 
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 
            'application/vnd.ms-excel', 
            'text/csv'
        ];

        if (file && !allowedTypes.includes(file.type)) {
            alert("Invalid file type. Please upload a PDF or Excel file.");
            setSelectedFile(null); // Reset file if invalid
            fileInputRef.current.value = ""; // Clear file input value
            return;
        }
        setSelectedFile(file);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsButtonLoading(true);

        if (!selectedFile) {
            setValidated(true);
            setIsButtonLoading(false);
            return;
        }

        try {
            // Call the callback function with the entire list of added companies
            await onFileSubmit(selectedFile, true);
            setValidated(false);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsButtonLoading(false);
        }
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="us-stock">
            <Form.Group controlId="fileUpload">
                <Form.Control type="file" onChange={handleFileChange} ref={fileInputRef} required/>
                <Form.Control.Feedback type="invalid">
                    Please choose a file to upload.
                </Form.Control.Feedback>
            </Form.Group>
            <div className='download-sample'><a href=''>Download Sample File</a></div>
            <Form.Group controlId="uploadSubmit" className="btn-container">
                <Button variant="primary" type="submit" disabled={isButtonLoading}>
                {isButtonLoading ? 'Uploading...' : 'Upload'}
                </Button>
            </Form.Group>
        </Form>
    );
}

export default FileUpload;