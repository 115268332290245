import React, {useState, useEffect} from "react";
import TotalPortfolioValue from "../TotalPortfolioValue"; // Import the TotalPortfolioValue component
import PreciousMetalsForm from "./PreciousMetalsForm";
import PreciousMetalsCell from "./PreciousMetalsCell";
import DynamicTabs from "../DynamicTabs";

import Loader from "../Loader";
import EmptyState from "../EmptyState";
import { fetchData, removeLocalStorageItem, apiRequest } from "../../utils/helpers";
import { message } from "antd";
import { apiConfig } from "../../ApiConfig";
import PreciousMetalsList from "./PreciousMetalsList";
import PreciousMetalsAnalytics from "./PreciousMetalsAnalytics";
import PreciousMetalsTransaction from "./PreciousMetalsTransaction";

import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";

function PreciousMetals () {

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true);
    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);
    // State to store the list of precious metals
    const [preciousMetals, setPreciousMetals] = useState([]);
    const [portfolioValue, setPortfolioValue] = useState({});
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    const url = apiConfig.PRECIOUSMETALSSUMMARY;
    const method = 'GET';
    const localStorageKey = 'preciousMetalsList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
        setIsLoading(false);
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('PreciousMetals', 'request', 'Loading precious metals data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            if (!data) {
                setPortfolioValue(null);
                setPreciousMetals([]);
                setPortfolioValue1DChange(null);
                setPortfolioValueReturns(null);
            }else {
                // Set state with actual data
                setPortfolioValue(data);
                setPreciousMetals(data.asset_details || []);
                setPortfolioValue1DChange(data['1d_change'] || [0, 0]);
                setPortfolioValueReturns(data['returns'] || [0, 0]);
                trackEvent('PreciousMetals', 'success', 'Successfully loaded precious metals data');
            }
        } catch (error) {
            message.error({
                content: `Error fetching precious metals list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
            trackEvent('PreciousMetals', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };

    const portfolioData = {
        module: 'preciousmetals',
        value: portfolioValue?.curr_val || 0,  // Use optional chaining and a fallback value
        dayChangeValue: portfolioValue1DChange?.[0] || 0,  // Optional chaining with fallback
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
        plusIconTitle: 'Add Precious Metals' // Title for the plus icon
    };    

    const tabs = [
        { key: 'preciousmetalslist', name: 'My Metals', component: PreciousMetalsList },
        { key: 'analytics', name: 'Analytics', component:  PreciousMetalsAnalytics},
        { key: 'transactions', name: 'Transactions', component: PreciousMetalsTransaction },
    ];

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
        trackEvent('PreciousMetals', 'openForm', 'Opening form modal');
    }

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (formData) => {
        try {
            trackEvent('PreciousMetals', 'request', 'Submitting precious metals transaction');

            const url = apiConfig.PRECIOUSMETALSTRANSACTIONS; // Replace with your actual API URL
            formData = { transactions: formData };
            // Call the API to post the form data
            const result = await apiRequest(url, 'POST', formData);
            if (result.statusCode === 200){
                message.success({
                    content: result.message,
                    duration: 5,
                })
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('PreciousMetals', 'success', 'Successfully submitted transaction');
            } if (result.error) {
                message.error({
                  content: result.error.message || 'An error occurred',
                  duration: 8, // Duration in seconds
                });
                trackEvent('PreciousMetals', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                message.error({
                    content: `${result.message}`,
                    duration: 8, // Duration in seconds
                });
                trackEvent('PreciousMetals', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            message.error({
                content: `${error.message}`,
                duration: 3, // Duration in seconds
            });
            trackEvent('PreciousMetals', 'error', `APi (${url}): ${error.message}`);
        }
    };

    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        removeLocalStorageItem('preciousMetalsList');
        removeLocalStorageItem('preciousMetalsAnalyticsData');
        removeLocalStorageItem('preciousMetalsTransactionList');
        removeLocalStorageItem('PRECIOUSMETALSCURRVAL');
    };
    return (
        <div>
        {isLoading ? ( 
            <Loader />
        ) : preciousMetals.length === 0 ? ( // Check if stock list is empty
            <EmptyState openModal={openModal}/>  // Render the Empty State component when there's no data
        ) : (
        <div className="container precious-metals pad-right-unset pad-left-unset">
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'Precious Metals'} openForm={() => openModal()}/>
            <DynamicTabs tabs={tabs} data={preciousMetals} module={'precious-metals'}/>

            <div className="float-container">
                <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
                    <i className="fa fa-plus my-float"></i>
                </a>
            </div>
        </div>
        )}
        {/* Render the PreciousMetalsForm component with props for modal state, closing, and form submission */}
        <PreciousMetalsForm 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            onFormSubmit={handleFormSubmit}
        />
        </div>
    );
}

export default PreciousMetals;