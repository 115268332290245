import React, {useState, useEffect, useMemo} from "react";
import { message } from "antd";
import { fetchData } from "../utils/helpers";
import ApexChart from "./ApexChart";
import PieChart from "./PieChart";
import Analytics from "./Analytics";
import EmptyState from "./EmptyState";

import TotalPortfolioValue from "./TotalPortfolioValue";
import '../css/MutualFund.css';

import Loader from "./Loader";

function NetWorth() {
    
    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [networthValue, setNetworthValue] = useState({});

    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueAssets, setPortfolioValueAssets] = useState({}); 

    const [baseDailyTrend, setBaseDailyTrend] = useState({}); 
    const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); 
    const [referenceDailyTrend, setReferenceDailyTrend] = useState({}); 
    const [referenceWeeklyTrend, setReferenceWeeklyTrend] = useState({}); 

    const url = 'https://moneylens.proxy.beeceptor.com/api/pm/networth';
    const method = 'GET';
    const localStorageKey = 'networth-dashboard';
    
    const loadData = async (refresh = false) => {
        try {
            const data = await fetchData(url, method, localStorageKey, refresh);
            if(data){
              updateStateWithData(data);
            }
        } catch (error) {
            message.error({
                content: `Error fetching dashboard`,
                duration: 3, // Duration in seconds
            });
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    // Fetch asset data when the component mounts
    useEffect(() => {
      loadData();
    }, [url, method, localStorageKey]);
    
    
    
    const updateStateWithData = (result) => {
        setNetworthValue(result);
        setPortfolioValueAssets(result['assets'])
        setPortfolioValue1DChange(result['assets']['1d_change'])

        const baseDailyTrend = result.historic_trend.base_trend.daily_data;
        setBaseDailyTrend(baseDailyTrend);
        const baseWeeklyTrend = result.historic_trend.base_trend.weekly_data;
        setBaseWeeklyTrend(baseWeeklyTrend);
        const referenceDailyTrend = result.historic_trend.reference_trend.daily_data;
        setReferenceDailyTrend(referenceDailyTrend);
        const referenceWeeklyTrend = result.historic_trend.reference_trend.weekly_data;
        setReferenceWeeklyTrend(referenceWeeklyTrend);
    }

    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
    };

    const portfolioData = {
        module: 'networths',
        value: portfolioValueAssets?.curr_val || 0,
        dayChangeValue: portfolioValue1DChange?.[0] || 0,
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: networthValue?.networth || 0,
        returns: 0,
        returnspercent: 0,
        plusIcon: false,
    };
    
    const initialRows = [
        {
          id: 'row1',
          columns: [
            { id: '1', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
          ],
        },
        {
          id: 'row2',
          columns: [
            { id: '2', header: 'Geo Allocation', contentId: 'geo-distribution'},
            { id: '3', header: 'Market Cap', contentId: 'market-cap'},
          ],
        },
        {
          id: 'row3',
          columns: [
            { id: '4', header: 'Asset Classes', contentId: 'assets-class'},
            { id: '5', header: 'Top Companies', contentId: 'company'},
          ]
        },
        {
          id: 'row4',
          columns: [
            { id: '6', header: 'Sector Allocation', contentId: 'sector-distribution'},
            { id: '7', header: '', contentId: ''}
          ]
        },
      ];
    
    const contentMap = useMemo(() => ({
      'historic-trend' : <div className='holding-chart-container'>
                            <ApexChart baseTrend={baseDailyTrend} referenceTrend={referenceDailyTrend} baseWeeklyTrend={baseWeeklyTrend} referenceWeeklyTrend={referenceWeeklyTrend}/>
                          </div>,
      'geo-distribution' : <PieChart pieData={networthValue.geo_distribution} id='geo-distribution' width='480' height='250' />,
      'market-cap' : <PieChart pieData={networthValue.market_cap} id='market-cap' width='480' height='250' />,
      'assets-class' : <PieChart pieData={networthValue.asset_class} id='assets-class' width='480' height='250' />,
      'sector-distribution' : <PieChart pieData={networthValue.sector_distribution} id='sector-distribution' width='480' height='250' />,
      'company' : <PieChart pieData={networthValue.companies} id='company' width='480' height='250' />,
    }), [networthValue, baseDailyTrend, baseWeeklyTrend, referenceDailyTrend, referenceWeeklyTrend]);
    return (
        <div>
        {isLoading ? (
            <Loader /> // Show the loader while data is loading
        )  : Object.keys(networthValue).length === 0 ? ( // Check if the object is empty
            <EmptyState />  // Render the Empty State component when there's no data
        ) : (
        <div className="container networth pad-right-unset pad-left-unset">
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'NetWorth'}/>
            <Analytics initialRows={initialRows} contentMap={contentMap} module={'networth-analytics'}/>
        </div>
        )}
        </div>
    );
}

export default NetWorth;