// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form, Nav, Tab } from 'react-bootstrap'; // Import Modal, Button, and Form components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { formatRupees, ConvertDateToMilliseconds, formatDateToInputValue } from '../../utils/helpers';

// Define the PreciousMetalsForm functional component
const PreciousMetalsForm = ({ isOpen, closeModal, onFormSubmit, filledData, onEdit }) => {
    // Initial form data template for each metal
    const initialFormData = {
        investment: '', // Field for the invested amount
        weight: '', // Field for the weight in grams
        date: '', // Field for the date of investment
    };
  
    // State to manage the form data for each metal
    const [goldFormData, setGoldFormData] = useState({ metal_code: 'au', ...initialFormData }); // State for Gold form data
    const [silverFormData, setSilverFormData] = useState({ metal_code: 'ag', ...initialFormData }); // State for Silver form data
    const [palladiumFormData, setPalladiumFormData] = useState({ metal_code: 'pd', ...initialFormData }); // State for Palladium form data
    const [platinumFormData, setPlatinumFormData] = useState({ metal_code: 'pt', ...initialFormData }); // State for Platinum form data
    const [isGoldDateFocused, setIsGoldDateFocused] = useState(false);
    const [isSilverDateFocused, setIsSilverDateFocused] = useState(false);
    const [isPalladiumDateFocused, setIsPalladiumDateFocused] = useState(false);
    const [isPlatinumDateFocused, setIsPlatinumDateFocused] = useState(false);
    // State to manage form validation for each metal
    const [validated, setValidated] = useState({ gold: false, silver: false, palladium: false, platinum: false }); // Validation state
    // State to manage dirty form status for each metal
    const [isFormDirty, setIsFormDirty] = useState({ gold: false, silver: false, palladium: false, platinum: false }); // Dirty flag state

    // State to manage the active tab
    const [activeKey, setActiveKey] = useState('gold'); // Default to 'gold' tab
    const [isButtonLoading, setIsButtonLoading] = useState(false); // Loading state for button
    
    const metalMap = {
        au: 'gold',
        ag: 'silver',
        pd: 'palladium',
        pt: 'platinum'
    }

    // Check if the tab should be disabled based on onEdit
    const isTabDisabled = (tab) => {
        // Disable all tabs except the active one if onEdit is true
        if (onEdit) {
            return tab !== activeKey;
        }
        return false; // If onEdit is false, no tab should be disabled
    };
    // Effect to reset the form data and active tab when the modal opens or filledData changes
    useEffect(() => {
        if (isOpen) { // If the modal is open
           // Reset form data for each metal to initial values
           setGoldFormData({ metal_code: 'au', ...initialFormData });
           setSilverFormData({ metal_code: 'ag', ...initialFormData });
           setPalladiumFormData({ metal_code: 'pd', ...initialFormData });
           setPlatinumFormData({ metal_code: 'pt', ...initialFormData });

           // Reset validation and dirty states for each metal
           setValidated({ gold: false, silver: false, palladium: false, platinum: false });
           setIsFormDirty({ gold: false, silver: false, palladium: false, platinum: false });

           // Set the active tab to 'gold'
           setActiveKey('gold');
        }
    }, [isOpen]); // Dependency array to trigger effect
    
    useEffect(() => {
        if (filledData) {
            const setFormData = (filledData.metal_code === 'au' ? setGoldFormData :
                filledData.metal_code === 'ag' ? setSilverFormData :
                filledData.metal_code === 'pd' ? setPalladiumFormData : setPlatinumFormData);
            setFormData({ ...filledData, investment: formatRupees(filledData.investment), date: formatDateToInputValue(filledData.date) });
            setActiveKey(metalMap[filledData.metal_code]);
        }
    }, [filledData]);
    // Function to handle input changes for any metal's form
    const handleInputChange = (e, metal) => {
        const { id, value } = e.target; // Destructure input ID and value
        let newValue = value;
        // If input is for a number field, remove commas for processing
        if (id === 'investment') {
            let rawValue = value.replace(/,/g, ''); // Remove commas from the input value
            newValue = formatRupees(rawValue); // Format the number with commas
        }
        // Determine which form's state to update based on the metal type
        const updatedFormData = (metal === 'gold' ? goldFormData :
                                metal === 'silver' ? silverFormData :
                                metal === 'palladium' ? palladiumFormData : platinumFormData);
        // Determine which setter function to use for the specific metal
        const setFormData = (metal === 'gold' ? setGoldFormData :
                             metal === 'silver' ? setSilverFormData :
                             metal === 'palladium' ? setPalladiumFormData : setPlatinumFormData);

        // Update the form state for the selected metal
        setFormData({ ...updatedFormData, [id]: newValue });
        // Mark the form as dirty (modified)
        setIsFormDirty({ ...isFormDirty, [metal]: true });
    };
    
    // Handle form submission
    const handleSubmit = async (event, formData) => {
        event.preventDefault(); // Prevent default form submission behavior
        const form = event.currentTarget; // Get the form element
        
        // Check form validity
        if (form.checkValidity() === false) {
            event.stopPropagation(); // Stop submission if form is invalid
        } else {
            setIsButtonLoading(true); // Set button to loading state
            try {
                await onFormSubmit(formData);
            } catch (error) {
                // Handle network or other errors
                console.error('API Error:', error);
            } finally {
                setIsButtonLoading(false); // Set loading state back to false
            }
        }
    };

    // Handle submission for all forms
    const handleSubmitAll = (e) => {
        e.preventDefault();

        // Track if all forms are valid
        let isValid = true;

        // Initialize the validated state
        const updatedValidated = { ...validated };

        // Check if all fields are blank, including date fields
        const allFieldsEmpty = 
        !goldFormData.investment && !goldFormData.weight && !goldFormData.date &&
        !silverFormData.investment && !silverFormData.weight && !silverFormData.date &&
        !palladiumFormData.investment && !palladiumFormData.weight && !palladiumFormData.date &&
        !platinumFormData.investment && !platinumFormData.weight && !platinumFormData.date;

        // If all fields are blank, allow submission (skip validation)
        if (allFieldsEmpty) {
            isValid = false;
            updatedValidated.gold = true;
            updatedValidated.silver = true;
            updatedValidated.palladium = true;
            updatedValidated.platinum = true;
        } else {
            // Validate gold form
            if ((goldFormData.investment && !goldFormData.weight) || 
                (!goldFormData.investment && goldFormData.weight) || 
                (goldFormData.investment && !goldFormData.date) || 
                (goldFormData.weight && !goldFormData.date)) {
                isValid = false;
                updatedValidated.gold = true;
            }

            // Validate silver form
            if ((silverFormData.investment && !silverFormData.weight) || 
                (!silverFormData.investment && silverFormData.weight) || 
                (silverFormData.investment && !silverFormData.date) || 
                (silverFormData.weight && !silverFormData.date)) {
                isValid = false;
                updatedValidated.silver = true;
            }

            // Validate palladium form
            if ((palladiumFormData.investment && !palladiumFormData.weight) || 
                (!palladiumFormData.investment && palladiumFormData.weight) || 
                (palladiumFormData.investment && !palladiumFormData.date) || 
                (palladiumFormData.weight && !palladiumFormData.date)) {
                isValid = false;
                updatedValidated.palladium = true;
            }

            // Validate platinum form
            if ((platinumFormData.investment && !platinumFormData.weight) || 
                (!platinumFormData.investment && platinumFormData.weight) || 
                (platinumFormData.investment && !platinumFormData.date) || 
                (platinumFormData.weight && !platinumFormData.date)) {
                isValid = false;
                updatedValidated.platinum = true;
            }
        }

        // If any form is invalid, set the validation state and return
        if (!isValid) {
            setValidated(updatedValidated);
            return; // Stop the submission
        }

        // If all forms are valid, proceed with submission
        let mergedDataArray = [];

        // Merge form data for each metal into separate objects within the array
        if (isFormDirty.gold) {
            const updatedGoldForm = convertDateInFormData(goldFormData);
            mergedDataArray.push(updatedGoldForm); // Push gold form data as an object
        }
        if (isFormDirty.silver) {
            const updatedSilverForm = convertDateInFormData(silverFormData);
            mergedDataArray.push(updatedSilverForm); // Push silver form data as an object
        }
        if (isFormDirty.palladium) {
            const updatedPalladiumForm = convertDateInFormData(palladiumFormData);
            mergedDataArray.push(updatedPalladiumForm); // Push palladium form data as an object
        }
        if (isFormDirty.platinum) {
            const updatedPlatinumForm = convertDateInFormData(platinumFormData);
            mergedDataArray.push(updatedPlatinumForm); // Push platinum form data as an object
        }

        // Call handleSubmit with the array of merged data
        handleSubmit(e, mergedDataArray);
    };


    const convertDateInFormData = (formData) => {
        return {
            ...formData,
            date: ConvertDateToMilliseconds(formData.date), // Convert date here
        };
    };

    // Handle modal close
    const handleClose = () => {
        closeModal(); // Close the modal
    };

    // Render the form modal
    return (
        <Modal show={isOpen} onHide={handleClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Precious Metals Details</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>
                <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}> {/* Tab container with dynamic active tab */}
                    <Nav variant="tabs">
                        <Nav.Item>
                            <Nav.Link eventKey="gold" disabled={isTabDisabled('gold')}>Gold</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="silver" disabled={isTabDisabled('silver')}>Silver</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="palladium" disabled={isTabDisabled('palladium')}>Palladium</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="platinum" disabled={isTabDisabled('platinum')}>Platinum</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="gold">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.gold}>
                                <Form.Group controlId="investment">
                                    <Form.Control
                                        type="text"
                                        placeholder="Investment Amount *"
                                        required
                                        autoComplete='off'
                                        value={goldFormData.investment}
                                        onChange={(e) => handleInputChange(e, 'gold')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the investment amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="number"
                                        placeholder="Weight in gm *"
                                        required
                                        autoComplete='off'
                                        value={goldFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'gold')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <div className={`position-relative ${!goldFormData.date ? 'placeholder-date' : ''}`}>
                                    {!goldFormData.date && !isGoldDateFocused && (
                                        <span className='date-placeholder'>
                                        Investment Date *
                                        </span>
                                    )}
                                    <Form.Control
                                        type="date"
                                        value={goldFormData.date}
                                        onChange={(e) => handleInputChange(e, 'gold')}
                                        placeholder="Investment Date *" // Note: The placeholder might not show in some browsers with type="date"
                                        required
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => {
                                            if (!goldFormData.date) {
                                                setIsGoldDateFocused(true); // Only set focus state if date is not filled
                                                e.target.showPicker();  // Show the picker when focused
                                            }
                                        }}
                                        onBlur={() => setIsGoldDateFocused(false)} // Show placeholder on blur if no date
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select the date.
                                    </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="silver">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.silver}>
                                <Form.Group controlId="investment">
                                    <Form.Control
                                        type="text"
                                        placeholder="Investment Amount *"
                                        required
                                        autoComplete='off'
                                        value={silverFormData.investment}
                                        onChange={(e) => handleInputChange(e, 'silver')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the investment amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="text"
                                        placeholder="Weight in gm *"
                                        required
                                        autoComplete='off'
                                        value={silverFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'silver')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <div className={`position-relative ${!silverFormData.date ? 'placeholder-date' : ''}`}>
                                    {!silverFormData.date && !isSilverDateFocused && (
                                        <span className='date-placeholder'>
                                        Investment Date *
                                        </span>
                                    )}
                                    <Form.Control
                                        type="date"
                                        value={silverFormData.date}
                                        onChange={(e) => handleInputChange(e, 'silver')}
                                        required
                                        placeholder="Investment Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => {
                                            if (!silverFormData.date) {
                                                setIsSilverDateFocused(true); // Only set focus state if date is not filled
                                                e.target.showPicker();  // Show the picker when focused
                                            }
                                        }}
                                        onBlur={() => setIsSilverDateFocused(false)} // Show placeholder on blur if no date
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select the date.
                                    </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="palladium">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.palladium}>
                                <Form.Group controlId="investment">
                                    <Form.Control
                                        type="text"
                                        placeholder="Investment Amount *"
                                        required
                                        autoComplete='off'
                                        value={palladiumFormData.investment}
                                        onChange={(e) => handleInputChange(e, 'palladium')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the investment amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="text"
                                        placeholder="Weight in gm *"
                                        required
                                        autoComplete='off'
                                        value={palladiumFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'palladium')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <div className={`position-relative ${!palladiumFormData.date ? 'placeholder-date' : ''}`}>
                                    {!palladiumFormData.date && !isPalladiumDateFocused && (
                                        <span className='date-placeholder'>
                                        Investment Date *
                                        </span>
                                    )}
                                    <Form.Control
                                        type="date"
                                        value={palladiumFormData.date}
                                        onChange={(e) => handleInputChange(e, 'palladium')}
                                        required
                                        placeholder="Investment Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => {
                                            if (!palladiumFormData.date) {
                                                setIsPalladiumDateFocused(true); // Only set focus state if date is not filled
                                                e.target.showPicker();  // Show the picker when focused
                                            }
                                        }}
                                        onBlur={() => setIsPalladiumDateFocused(false)} // Show placeholder on blur if no date
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select the date.
                                    </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                        <Tab.Pane eventKey="platinum">
                            {/* Form with validation */}
                            <Form noValidate validated={validated.platinum}>
                                <Form.Group controlId="investment">
                                    <Form.Control
                                        type="text"
                                        placeholder="Investment Amount *"
                                        required
                                        autoComplete='off'
                                        value={platinumFormData.investment}
                                        onChange={(e) => handleInputChange(e, 'platinum')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the investment amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="weight">
                                    <Form.Control
                                        type="text"
                                        placeholder="Weight in gm *"
                                        required
                                        autoComplete='off'
                                        value={platinumFormData.weight}
                                        onChange={(e) => handleInputChange(e, 'platinum')}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please enter the weight.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="date">
                                    <div className={`position-relative ${!platinumFormData.date ? 'placeholder-date' : ''}`}>
                                    {!platinumFormData.date && !isPlatinumDateFocused && (
                                        <span className='date-placeholder'>
                                        Investment Date *
                                        </span>
                                    )}
                                    <Form.Control
                                        type="date"
                                        value={platinumFormData.date}
                                        onChange={(e) => handleInputChange(e, 'platinum')}
                                        required
                                        placeholder="Investment Date" // Note: The placeholder might not show in some browsers with type="date"
                                        style={{
                                            width: '100%',
                                            cursor: 'pointer',
                                        }}
                                        onFocus={(e) => {
                                            if (!platinumFormData.date) {
                                                setIsPlatinumDateFocused(true); // Only set focus state if date is not filled
                                                e.target.showPicker();  // Show the picker when focused
                                            }
                                        }}
                                        onBlur={() => setIsPlatinumDateFocused(false)} // Show placeholder on blur if no date
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select the date.
                                    </Form.Control.Feedback>
                                    </div>
                                </Form.Group>
                            </Form>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
                <Form.Group controlId="preciousMetalsSubmit" className="btn-container">
                    <Button variant="primary" onClick={handleSubmitAll} disabled={isButtonLoading}>
                    {isButtonLoading ? (onEdit ? 'Updating...' : 'Saving...') : (onEdit ? 'Update' : 'Save')}
                    </Button>
                </Form.Group>
                {/* Form with validation */}
            </Modal.Body>
        </Modal>
    );
};

// Export the PreciousMetalsForm component as the default export
export default PreciousMetalsForm;
