import React, { useState, useMemo, useEffect } from 'react';
import { message } from "antd";
import { fetchData } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import PieChart from '../PieChart';
import ApexChart from '../ApexChart';
import Analytics from '../Analytics';
import Loader from '../Loader'; // Import the loader component

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';

function PrivateEquityAnalytics() {

  const location = useLocation();  // Get the current location
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [selectedChart, setSelectedChart] = useState('current');
  const [analyticsValues, setAnalyticsValues] = useState({}); // State to hold asset values
  const [holdingInsights, setHoldingInsights] = useState({}); // State to hold asset values
  const [distribution, setDistribution] = useState([]);
  const [baseDailyTrend, setBaseDailyTrend] = useState({}); // State to hold asset values
  const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); // State to hold asset values

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);

  const url = apiConfig.PRIVATEEQUITYANALYTICS;
  const method = 'GET';
  const localStorageKey = 'privateEquityAnalyticsData';
  // Fetch asset data when the component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        trackEvent('PrivateEquity Analytics', 'request', 'Loading private equity analytics data');
        const data = await fetchData(url, method, localStorageKey);
        updateStateWithData(data);
        trackEvent('PrivateEquity Analytics', 'success', 'Successfully private equity analytics data');
      } catch (error) {
        message.error({
          content: `Error fetching analytics data: ${error.message}`,
          duration: 3, // Duration in seconds
        });
        setError(`Error fetching analytics data: ${error.message}`);
        trackEvent('PrivateEquity Analytics', 'error', `APi (${url}): ${error.message}`);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  loadData();
  }, [url, method, localStorageKey]);
  
  const updateStateWithData = (result) => {
    // Assuming the API returns an object with asset values
    setAnalyticsValues(result);
    setHoldingInsights(result.holdings_insights)
    setDistribution(result.holdings_insights.distribution);

    const baseDailyTrend = result.historic_trend.base_trend.daily_data;
    setBaseDailyTrend(baseDailyTrend);
    const baseWeeklyTrend = result.historic_trend.base_trend.weekly_data;
    setBaseWeeklyTrend(baseWeeklyTrend);
    trackEvent('PrivateEquity Analytics', 'success', 'Loaded private equity analytics data');
  }


  const initialRows = [
    {
      id: 'row1',
      columns: [
        { id: '1',header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
      ],
    },
    {
      id: 'row2',
      columns: [
        { id: '2', header: 'Portfolio Breakdown by Company', contentId: 'distribution'},
      ]
    }
  ];

  const contentMap = useMemo(() => ({
    'historic-trend' : <div className='holding-chart-container'>
                          <ApexChart baseTrend={baseDailyTrend} referenceTrend={false} baseWeeklyTrend={baseWeeklyTrend} referenceWeeklyTrend={false}/>
                        </div>,
    'distribution' : <PieChart pieData={distribution.investment} id='investment' width='480' height='250' />,
   }), [analyticsValues, holdingInsights, distribution, baseDailyTrend, baseWeeklyTrend]);
  
  return (
    <div className='rsus-stock-analytics'>
    {isLoading ? (
                <Loader /> // Show the loader while data is loading
            ) : error ? (
              <div>{error}</div>
            ) : (
      <Analytics initialRows={initialRows} contentMap={contentMap} module={'privateequity-analytics'}/>
     )}
     </div>
  );
}

export default PrivateEquityAnalytics;
