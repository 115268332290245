import React, { useState } from "react";
import { formatDate, formatRupees } from "../../utils/helpers";
import MFForm from "./MFForm";
import { message } from "antd";
import { apiConfig } from "../../ApiConfig";
import { apiRequest } from "../../utils/helpers";

function MFIndividualTransactionList({ transaction, sceheme_code, handleRefresh }) {
    const [transactionDetails, setTransactionDetails] = useState(transaction);
    const [showModal, setShowModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);

    const handleEdit = (transaction) => {
        setCurrentTransaction({ ...transaction, companyid: parseInt(sceheme_code, 10), amount: formatRupees(transaction.amount) });
        setShowModal(true);
    };    

    // Handle modal close
    const handleDelete = async (id) => {
        const confirmClose = window.confirm('Are you sure you want to delete?');
        if (!confirmClose) {
            return; // Prevent closing if user cancels
        }
        try {
            const url = `${apiConfig.MFTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'DELETE', null);
            if(result.statusCode === 200){
                handleRefresh();
                handleCloseModal(); // Close the modal after submission
            }else {
                message.error({
                    content: `Failed to update form: ${result.message}`,
                    duration: 3, // Duration in seconds
                });
            }
        } catch (error) {
            message.error({
                content: `Failed to update form: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentTransaction(null);
    };

    const handleFormSubmit = async (formData) => {
        // Destructure to exclude name from formData
        const { name, id, nav_price, ...rest } = formData[0]; // Destructure to exclude name and capture companyid

        // Create updatedFormData with necessary modifications
        let updatedFormData = {
            ...rest // Spread the rest of the properties from formData
        };

        // Logic to conditionally remove amount for sell transactions
        if (updatedFormData.type === 'sell') {
            if (updatedFormData.quantity) { // Check if quantity is available
                delete updatedFormData.amount; // Remove amount if quantity is present
            }else if (updatedFormData.amount) {
                delete updatedFormData.quantity; // Remove quantity if amount is present
            }
        } else {
            delete updatedFormData.quantity; // Remove quantity if amount is present
        }
        try {
            const url = `${apiConfig.MFTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'PUT', updatedFormData);
            if(result.statusCode === 200){
                handleRefresh();
                handleCloseModal(); // Close the modal after submission
            }else {
                message.error({
                    content: `Failed to update form: ${result.message}`,
                    duration: 3, // Duration in seconds
                });
            }
        } catch (error) {
            message.error({
                content: `Failed to update form: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        }
    };

    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Investment</th>
                            <th scope="col">Qty</th>
                            <th scope="col">Price</th>
                            <th scope="col">Type</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transactionDetails.length > 0 ? (
                            transactionDetails.map(details => (
                                <tr key={details.id}>
                                    <td>₹{formatRupees(details.amount)}</td>
                                    <td>{details.quantity}</td>
                                    <td>₹{formatRupees(details.nav_price)}</td>
                                    <td style={{textTransform: 'capitalize'}}>{details.type}</td>
                                    <td>{formatDate(parseInt(details.date))}</td>
                                    <td>
                                        <i className="fas fa-edit edit-company" onClick={(e) => { e.preventDefault(); handleEdit(details); }}></i>
                                        <i className="fas fa-trash delete-company" onClick={(e) => { e.preventDefault(); handleDelete(details.id); }}></i>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No transactions available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Render the modal form */}
            {showModal && (
                <MFForm
                    isOpen={showModal}
                    closeModal={handleCloseModal}
                    onFormSubmit={handleFormSubmit}
                    initialFormData={currentTransaction}
                    defaultForm='manual'
                    onEdit={true}
                />
            )}
        </div>
    );
}

export default MFIndividualTransactionList;
