import React, {useState} from "react";
import '../../css/StockCell.css';
import classNames from 'classnames';
import { message } from "antd";
import { isNegative, shortFormatRupees, apiRequest, removeLocalStorageItem, getFullUrl, removeSign } from '../../utils/helpers';
import { apiConfig } from "../../ApiConfig";

import RealEstateForm from "./RealEstateForm";

function RealEstateCell ({data}) {
    const returns = data.returns;
    const returnsPercentage = isNegative(parseInt(returns[0]));
    const profitLossSymbol = (returnsPercentage) ? '' : '+';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentProperty, setCurrentProperty] = useState(null);

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const handleEdit = (property) => {
        setCurrentProperty({ ...property});
        setIsModalOpen(true);
    }; 

    // Handle modal close
    const handleDelete = async (id) => {
        const confirmClose = window.confirm('Are you sure you want to delete?');
        if (!confirmClose) {
            return; // Prevent closing if user cancels
        }
        try {
            const url = `${apiConfig.ADDPROPERTY}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'DELETE', null);
            if(result.statusCode === 200){
                handleRefresh();
            }else {
                message.error({
                    content: `Failed to update form: ${result.message}`,
                    duration: 3, // Duration in seconds
                });
            }
        } catch (error) {
            message.error({
                content: `Failed to update form: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        }
    };

    const handleRefresh = () => {
        removeLocalStorageItem('realEstateList');
        removeLocalStorageItem('realEstateAnalyticsData');
        removeLocalStorageItem('realEstateTransactionList');
        removeLocalStorageItem('REALESTATECURRVAL');
        window.location.reload();
    };

    // Callback function to handle form submission
    const handleFormSubmit = async (formData, id = false) => {
        try {
            const url = (id) ? `${apiConfig.ADDPROPERTY}/${id}` : apiConfig.ADDPROPERTY; // Replace with your actual API URL
            const method = (id) ? 'PUT' : 'POST';
            // Call the API to post the form data
            const result = await apiRequest(url, method, formData);
            if(result.statusCode === 200){
                handleRefresh();
                closeModal(); // Close the modal after submission
            }else {
                message.error({
                    content: `Failed to submit form: ${result.message}`,
                    duration: 3, // Duration in seconds
                });
            }
        } catch (error) {
            message.error({
                content: `Failed to submit form: ${error.message}`,
                duration: 3, // Duration in seconds
            });
            // Handle error (e.g., show a notification to the user)
        }
    };

    return (
        <div>
        <div className="broker-cell-container" key={data.id}>
            <div className="broker-cell-row">
                <div className="broker-cell-icon">
                    <img alt="" loading="lazy" width="32" height="32" decoding="async" data-nimg="1" src={getFullUrl('images/realestate.png')} style={{color: 'transparent'}}/>
                </div>
                <div className="broker-cell-name-container">
                    <p className="broker-cell-name" style={{textAlign: 'unset'}}>{data.name}</p>
                </div>
                <div className="action-container">
                    <div className="broker-cell-price-container" onClick={(e) => { handleEdit(data); }}>
                        <i className='fas fa-edit' style={{color: '#bdbdbd'}}></i>
                    </div>
                    <div className="broker-cell-price-container" onClick={(e) => { handleDelete(data.id); }}>
                        <i className='fas fa-trash' style={{color: '#bdbdbd'}}></i>
                    </div>
                </div>
            </div>
            <div className="broker-cell-row padding-unset">
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Investment</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(data.investment)}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Current Value</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(data.curr_val)}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Returns</p>
                    <p className={classNames('broker-cell-account returns-col', profitLossClass)}><span>₹{shortFormatRupees(returns[0])}</span> <span>({profitLossSymbol}{removeSign(returns[1])}%)</span></p>
                </div>
            </div>
        </div>
        {isModalOpen && (
            <RealEstateForm
                isOpen={isModalOpen}
                closeModal={closeModal}
                onFormSubmit={handleFormSubmit}
                filledData={currentProperty}
                onEdit={true}
            />
        )}
        </div>
    );
}

export default RealEstateCell;