import React, { useEffect, useState, useMemo } from "react";
import { formatRupees } from "../utils/helpers";
import classNames from 'classnames';
import Chart from 'react-apexcharts';
import ApexCharts from 'apexcharts';

function ApexChart({ baseTrend, referenceTrend, baseWeeklyTrend, referenceWeeklyTrend, referenceIndex = 'Nifty 50' }) {
    const [selectedTab, setSelectedTab] = useState("5D");

    const [graphLineColor, setGraphLineColor] = useState('#34a853');
    const [graphMiddleColor, setGraphMiddleColor] = useState('#b8e1c3');

    const [profitLossClass, setProfitLossClass] = useState('');
    const [rotateClass, setRotateClass] = useState('');

    const [absoluteValue, setAbsoluteValue] = useState(0);
    const [totalPercentage, setTotalPercentage] = useState('');

    const [niftyPercentage, setNiftyPercentage] = useState(0);
    const [niftyProfitLossClass, setNiftyProfitLossClass] = useState('');
    const [niftyRotateClass, setNiftyRotateClass] = useState('');
    const tabs = ['5D', '1M', '3M', '6M', '1Y', '3Y', '5Y', 'MAX'];
    // Determine which trend data to use based on the selected tab
    const isLongTerm = useMemo(() => ["3Y", "5Y", "MAX"].includes(selectedTab), [selectedTab]);
    const isOneAndThreeForTick = useMemo(() => ["1Y", "3Y"].includes(selectedTab), [selectedTab]);
    const isFiveAndMaxForTick = useMemo(() => ["5Y", "MAX"].includes(selectedTab), [selectedTab]);

    const currentGraphData = useMemo(() => {
        return (isLongTerm && baseWeeklyTrend?.length > 0) ? baseWeeklyTrend : (baseTrend?.length > 0 ? baseTrend : []);
    }, [baseWeeklyTrend, baseTrend, isLongTerm]);

    const currentReferenceData = useMemo(() => {
        return (isLongTerm && referenceWeeklyTrend?.length > 0) ? referenceWeeklyTrend : (referenceTrend?.length > 0 ? referenceTrend : []);
    }, [referenceWeeklyTrend, referenceTrend, isLongTerm]);

    // Memoized downsampled data
    const downsampledGraphData = useMemo(() => currentGraphData, [currentGraphData]);
    const downsampledReferenceData = useMemo(() => currentReferenceData, [currentReferenceData]);

    // Memoize the series data
    const series = useMemo(() => {
        if (!downsampledGraphData.length) {
            return [{ name: 'No Data', data: [] }];
        }
        
        if (!referenceTrend) {
            return [
                {
                    name: 'This Fund',
                    data: downsampledGraphData,
                }
            ];
        }
        return [
            {
                name: referenceIndex,
                data: downsampledReferenceData,
            },
            {
                name: 'This Fund',
                data: downsampledGraphData,
            }
        ];
    }, [downsampledGraphData, downsampledReferenceData, referenceTrend, referenceIndex]);

    const returnTickAmount = (tab) => {
        switch (tab) {
            case '5D':
                return 4;
            case '1M':
            case '3M':
            case '6M':
                return 4;
            case '1Y':
                return 5;
            case '3Y':
                return 2;
            case '5Y':
                return 4;
            default:
                return 4; // Default value if none matches
        }
    }
    const getTickInterval = (tab) => {
        switch (tab) {
            case '5D':
                return '1 day';
            case '1M':
                return '1 week'; // Adjust for your needs
            case '6M':
                return '1 month'; // Adjust for your needs
            case '1Y':
                return '1 month';
            case '3Y':
                return '3 months';
            case '5Y':
                return '6 months';
            case 'MAX':
                return '1 year';
            default:
                return '1 month'; // Default value if none matches
        }
    }
    const [previousTab, setPreviousTab] = useState(null);
const [tickAmount, setTickAmount] = useState(0);
const [labelFormat, setLabelFormat] = useState({});

// Base options shared across all configurations
const baseChartOptions = {
    chart: {
        id: 'area-datetime',
        type: 'area',
        height: 350,
        zoom: { autoScaleYaxis: true, enabled: false },
    },
    colors: referenceTrend ? ['#808080', graphMiddleColor] : [graphMiddleColor],
    annotations: {
        yaxis: [{ y: 30, borderColor: '#999' }],
        xaxis: downsampledGraphData && downsampledGraphData.length > 0
            ? [{ x: downsampledGraphData[downsampledGraphData.length - 1][0], borderColor: '#999', yAxisIndex: 0 }]
            : [], // Empty array if downsampledGraphData is empty
    },
    dataLabels: { enabled: false },
    markers: { size: 0, style: 'hollow' },
    yaxis: {
        title: { text: 'Price' },
        labels: { formatter: (value) => Math.round(value) },
    },
    stroke: {
        curve: 'smooth',
        width: [2, 2],
        colors: referenceTrend ? ['#808080', graphLineColor] : [graphLineColor],
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.9,
            stops: [0, 100],
        },
    },
    tooltip: {
        shared: true,
        intersect: false,
        x: {
            formatter: (value) => {
                const date = new Date(value);
                return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
            },
        },
        y: {
            formatter: (value) => `${value}`,
        },
    },
};

const options = useMemo(() => {
    if (!downsampledGraphData || downsampledGraphData.length === 0) {
        return {
            ...baseChartOptions,
            xaxis: {
                ...baseChartOptions.xaxis,
                tickAmount: 0,
                labels: {
                    formatter: () => '',
                },
            },
        };
    }

    // Define date format based on selectedTab
    let dateFormat;
    switch (selectedTab) {
        case '5D':
        case '1M':
        case '3M':
        case '6M':
            dateFormat = { month: 'short', day: 'numeric' }; // Format: Nov 4
            break;
        case '1Y':
            dateFormat = { month: 'short', year: 'numeric' }; // Format: Nov 2024
            break;
        case '3Y':
        case '5Y':
        case 'MAX':
        default:
            dateFormat = { year: 'numeric' }; // Format: 2024
            break;
    }
    // Format dates and filter for uniqueness
    const formattedDates = downsampledGraphData.map(item => {
        const date = new Date(item[0]);
        return date.toLocaleDateString('en-US', dateFormat);
    });
    const uniqueFormattedDates = Array.from(new Set(formattedDates));
    let newTickAmount = (uniqueFormattedDates.length > 4) ? Math.min(uniqueFormattedDates.length, 3) : (uniqueFormattedDates.length === 2) ? 1 : uniqueFormattedDates.length - 2;
    if (selectedTab === '3Y' && newTickAmount > 2) {
        newTickAmount = 2;
    }
    const isSameData = previousTab && JSON.stringify(downsampledGraphData) === JSON.stringify(previousTab.data);
   
    // Get the indices of the previous tab and selected tab
    const previousTabIndex = previousTab ? tabs.indexOf(previousTab.tab) : -1;
    const selectedTabIndex = tabs.indexOf(selectedTab);

    // Check if the transition is a reverse one
    const isReverseTransition = previousTabIndex !== -1 && selectedTabIndex < previousTabIndex;
    
    if (isSameData && !isReverseTransition) {
        // If data is the same, use the previous tickAmount and labelFormat
        newTickAmount = tickAmount;
        dateFormat = labelFormat;
    } else {
        // If data is different, update previous tab and state
        setPreviousTab({ tab: selectedTab, data: downsampledGraphData });
        setTickAmount(newTickAmount);
        setLabelFormat(dateFormat);
    }
    

    return {
        ...baseChartOptions,
        xaxis: {
            ...baseChartOptions.xaxis,
            tickAmount: newTickAmount,
            labels: {
                formatter: (value) => {
                    const date = new Date(value);
                    return date.toLocaleDateString('en-US', dateFormat);
                },
            },
        },
    };
}, [downsampledGraphData, selectedTab, referenceTrend, graphLineColor, graphMiddleColor]);




    
    
    // Common function to find the closest data point
    const findClosestDataPoint = (data, referenceDate) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i][0] >= referenceDate) {
              return data[i];
            }
        }
        return null; // No matching data point found
    };

    let totalAbsPercetage = 0;
    const calculateAbsoluteValueAndPercentage = (entryAbsValue, exitAbsValue) => {
        entryAbsValue = parseFloat(entryAbsValue);
        exitAbsValue = parseFloat(exitAbsValue)
        // Determine if exitValue is greater or smaller than entryValue
        if (exitAbsValue > entryAbsValue) {
            // Calculate percentage increase
            totalAbsPercetage = ((exitAbsValue - entryAbsValue) / entryAbsValue) * 100;
            setRotateClass('');
            setProfitLossClass('profit-color');

            setGraphLineColor('#34a853');
            setGraphMiddleColor('#b8e1c3');
        } else if (exitAbsValue < entryAbsValue) {
            // Calculate percentage decrease
            totalAbsPercetage = ((entryAbsValue - exitAbsValue) / entryAbsValue) * 100;
            setRotateClass('rotate-text');
            setProfitLossClass('loss-color');

            setGraphLineColor('#ea4335');
            setGraphMiddleColor('#f7bab5');
        }
        setTotalPercentage(`${(totalAbsPercetage).toFixed(2)}%`);
        setAbsoluteValue((exitAbsValue - entryAbsValue).toFixed(2));
    }

    const calculateNiftyPercentage = (entryAbsNiftyValue, exitAbsNiftyValue) => {
    
        if (exitAbsNiftyValue > entryAbsNiftyValue) {
          setNiftyPercentage(`${(((exitAbsNiftyValue - entryAbsNiftyValue) / entryAbsNiftyValue) * 100).toFixed(2)}%`);
          setNiftyProfitLossClass('profit-color');
          setNiftyRotateClass('');
        } else if (exitAbsNiftyValue < entryAbsNiftyValue) {
          setNiftyPercentage(`${(((entryAbsNiftyValue - exitAbsNiftyValue) / entryAbsNiftyValue) * 100).toFixed(2)}%`);
          setNiftyProfitLossClass('loss-color');
          setNiftyRotateClass('rotate-text');
        }
    }

    useEffect(() => {
        if (!downsampledGraphData.length) return;
    
        let latestDataPoint = downsampledGraphData[downsampledGraphData.length - 1];
        let latestDate = latestDataPoint[0];
    
        const calculateZoom = (startDate) => {
            ApexCharts.exec('area-datetime', 'zoomX', startDate, latestDate);
        };
    
        const handleGraphCalculation = (days, months, years) => {
            let referenceIndex, niftyReferenceIndex;
            let calculatedDate = new Date(latestDate);
            
            if (days) {
                referenceIndex = downsampledGraphData[downsampledGraphData.length - days];
                if (downsampledReferenceData.length) {
                    niftyReferenceIndex = downsampledReferenceData[downsampledReferenceData.length - days];
                }
            } else if (months || years) {
                if (months) calculatedDate.setMonth(calculatedDate.getMonth() - months);
                if (years) calculatedDate.setFullYear(calculatedDate.getFullYear() - years);
                referenceIndex = findClosestDataPoint(downsampledGraphData, calculatedDate.getTime());
                if (downsampledReferenceData.length) {
                    let niftyCalculatedDate = new Date(latestDate);
                    if (months) niftyCalculatedDate.setMonth(niftyCalculatedDate.getMonth() - months);
                    if (years) niftyCalculatedDate.setFullYear(niftyCalculatedDate.getFullYear() - years);
                    niftyReferenceIndex = findClosestDataPoint(downsampledReferenceData, niftyCalculatedDate.getTime());
                }
            } else {
                referenceIndex = downsampledGraphData[0];
                if (downsampledReferenceData.length) {
                    niftyReferenceIndex = downsampledReferenceData[0];
                }
            }
            
            if (referenceIndex) {
                calculateZoom(referenceIndex[0]);
                calculateAbsoluteValueAndPercentage(referenceIndex[1], latestDataPoint[1]);
            }
    
            if (niftyReferenceIndex) {
                const latestNiftyDataPoint = downsampledReferenceData[downsampledReferenceData.length - 1];
                calculateNiftyPercentage(niftyReferenceIndex[1], latestNiftyDataPoint[1]);
            }
        };
    
        switch (selectedTab) {
            case "5D":
                handleGraphCalculation(5, null, null);
                break;
            case '1M':
                handleGraphCalculation(30, null, null);
                break;
            case "3M":
                handleGraphCalculation(null, 3, null);
                break;
            case '6M':
                handleGraphCalculation(null, 6, null);
                break;
            case '1Y':
                handleGraphCalculation(null, null, 1);
                break;
            case "3Y":
                handleGraphCalculation(null, null, 3);
                break;
            case "5Y":
                handleGraphCalculation(null, null, 5);
                break;
            case "MAX":
                handleGraphCalculation();
                break;
            default:
                break;
        }
    }, [downsampledGraphData, downsampledReferenceData, selectedTab, calculateAbsoluteValueAndPercentage]);

    return (
        <div id="line-chart">
            <div className="company-info-graph-profit">
                {/* <span className="company-info-graph-profit-number text-bold">₹{formatRupees(absoluteValue)} <span className={classNames('', profitLossClass)}><span className={classNames('', rotateClass)}>▲</span>{totalPercentage}</span>
                </span> */}
                {referenceIndex && 
                <span className="nifty-50-col">
                    {referenceIndex}:
                    <span style={{fontWeight: '700'}} className={classNames('', niftyProfitLossClass)}>
                        <span className={classNames('', niftyRotateClass)}>▲</span>
                        {niftyPercentage}
                    </span>
                </span>
                }
                <span className="this-fund-col">
                    This Fund: 
                    <span className="company-info-graph-profit-number text-bold">&nbsp;₹{formatRupees(absoluteValue)}&nbsp;&nbsp;
                        <span className={classNames('', profitLossClass)}>
                            <span className={classNames('', rotateClass)}>▲</span>
                            {totalPercentage}
                        </span>
                    </span>
                </span>
                
            </div>
            <div className="company-info-tab-container">
                {tabs.map((tab) => (
                    <span
                        key={tab}
                        className={classNames('company-info-tab', { active: selectedTab === tab })}
                        onClick={() => setSelectedTab(tab)}
                    >
                        {tab}
                    </span>
                ))}
            </div>
            <Chart options={options} series={series} type="area" height={350} />
        </div>
    );
}

export default ApexChart;
