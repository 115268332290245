import React, { useState } from 'react';

const SliderWithInput = ({
    onChange,
    label,
    inputLabel,
    defaultValue = 0,
    min = 0,
    max = 100,
    step = 1,
    allowDecimal = false, // New prop to allow decimals
}) => {
    const [value, setValue] = useState(defaultValue); // Initial value

    const handleSliderChange = (e) => {
        const newValue = parseFloat(e.target.value);
        setValue(newValue); // Update value based on slider
        onChange(newValue); // Notify main component of value change
    };

    const handleInputChange = (e) => {
        const inputValue = parseFloat(e.target.value);
        
        // Validate input based on allowDecimal flag
        if (!isNaN(inputValue) && inputValue >= min && inputValue <= max) {
            if (allowDecimal || inputValue % 1 === 0) { // If decimals are allowed or input is an integer
                setValue(inputValue); // Update value based on text input
                onChange(inputValue); // Notify main component of value change
            }
        }
    };

    // Calculate the percentage of the value relative to the max value
    const percentage = Math.min((value - min) / (max - min) * 100, 100); // Ensure the percentage does not exceed 100

    return (
        <div className="slider-bar-container">
            <label className="slider-label">{label}</label>
            <div className="slider-input-container">
                <input
                    type="range"
                    min={min}
                    max={max}
                    value={value}
                    step={allowDecimal ? 0.5 : step} // Set step based on allowDecimal
                    onChange={handleSliderChange}
                    className="custom-slider"
                    style={{
                        background: `linear-gradient(to right, #002D62 ${percentage}%, #d3d3d3 ${percentage}%)`, // Fill color
                    }}
                />
                <div className="slider-input-group">
                    <input
                        type="number"
                        value={value}
                        onChange={handleInputChange}
                        className="slider-input"
                        min={min}
                        max={max}
                        step={allowDecimal ? 0.5 : ''} // Allow input to be in increments based on allowDecimal
                    />
                    <span className="input-suffix">{inputLabel}</span>
                </div>
            </div>
        </div>
    );
};

export default SliderWithInput;
