import React, {useState, useEffect} from "react";
import { message } from "antd";
import { fetchData, removeLocalStorageItem, apiRequest } from "../../utils/helpers";
import { apiConfig } from "../../ApiConfig";

import TotalPortfolioValue from "../TotalPortfolioValue";
import '../../css/MutualFund.css';

import DynamicTabs from "../DynamicTabs";
import MutualFundsLIst from './MutualFundsLIst';
import MutualFundAnalytics from './MutualFundAnalytics';
// import MutualFundTransactions from './MutualFundTransactions';
import Loader from "../Loader";
import EmptyState from "../EmptyState";
import MFForm from "./MFForm";
import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";

function MutualFund() {
    
    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true); // State for loading status

    const [portfolioValue, setPortfolioValue] = useState({});
    const [mutualFundList, setMutualFundList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    // State to manage whether the modal is open or closed
    const [isModalOpen, setIsModalOpen] = useState(false);

    const url = apiConfig.MFSUMMARY;
    const method = 'GET';
    const localStorageKey = 'mutualFundList';
    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('MutualFund', 'request', 'Loading mutual fund data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            if (!data) {
                setPortfolioValue(null);
                setMutualFundList([]);
                setPortfolioValue1DChange(null);
                setPortfolioValueReturns(null);
            }else {
                // Set state with actual data
                setPortfolioValue(data);
                setMutualFundList(data.asset_details || []);
                setPortfolioValue1DChange(data['1d_change'] || [0, 0]);
                setPortfolioValueReturns(data['returns'] || [0, 0]);
                trackEvent('MutualFund', 'success', 'Successfully loaded mutual fund data');
            }
        } catch (error) {
            message.error({
                content: `Error fetching mutual fund list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
            trackEvent('MutualFund', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };
    
    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        removeLocalStorageItem('mutualFundList');
        removeLocalStorageItem('mutualFundAnalyticsData');
        removeLocalStorageItem('mutualFundTransactionList');
        removeLocalStorageItem('MFCURRVAL');
    };

    const portfolioData = {
        module: 'MutualFund',
        value: portfolioValue?.curr_val || 0,
        dayChangeValue: portfolioValue1DChange?.[0] || 0,
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };
    
    const tabs = [
        { key: 'mutualfundslist', name: 'My Mutual Funds', component: MutualFundsLIst },
        { key: 'analytics', name: 'Analytics', component: MutualFundAnalytics },
        // { key: 'transactions', name: 'Transactions', component: MutualFundTransactions },
      ];
    
    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true); // Open the modal
        trackEvent('MutualFund', 'openForm', 'Opening form modal');
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (requestData, isFile = false ) => {
        let url = apiConfig.MFTRANSACTIONS;
        let formData = { transactions: requestData };

        if (isFile) {
            const fileData = new FormData();
            fileData.append("file", requestData);
            formData = fileData;
        } 
        
        try {
            trackEvent('MutualFund', 'request', 'Submitting mutual fund transaction');
            // Call the API to post the form data
            const result = await apiRequest(url, 'POST', formData);
            if (result.statusCode === 200){
                message.success({
                    content: result.message,
                    duration: 5,
                })
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('MutualFund', 'success', 'Successfully submitted transaction');
            } if (result.error) {
                message.error({
                  content: result.error.message || 'An error occurred',
                  duration: 8, // Duration in seconds
                });
                trackEvent('MutualFund', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                message.error({
                    content: `${result.message}`,
                    duration: 8, // Duration in seconds
                });
                trackEvent('MutualFund', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            message.error({
                content: `${error.message}`,
                duration: 8, // Duration in seconds
            });
            trackEvent('MutualFund', 'error', `APi (${url}): ${error.message}`);
        }
    };

    const initialFormData = {
        name: '', //name
        companyid: '', //  id
        amount: '', // Purchase price
        date: '', // Purchase date
    };
    return (
        <div>
        {isLoading ? (
            <Loader /> // Show the loader while data is loading
        ) : mutualFundList.length === 0 ?
            <EmptyState openModal={openModal}/>
        : (
        <div className="container mutualfund pad-right-unset pad-left-unset">
            <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'Mutual Funds'} openForm={() => openModal()}/>
            <DynamicTabs tabs={tabs} data={mutualFundList} module={'mutualfunds'}/>

            <div className="float-container">
                <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
                    <i className="fa fa-plus my-float"></i>
                </a>
            </div>
        </div>
        )}
        <MFForm 
            isOpen={isModalOpen} 
            closeModal={closeModal} 
            onFormSubmit={handleFormSubmit} 
            initialFormData={initialFormData}
            defaultForm='manual'
        />
        </div>
    );
}

export default MutualFund;