import React from "react";
import '../css/comingsoon.css';
import ComingSoon from "./ComingSoon";

function Debts() {
  const heading = 'Coming Soon: Your Complete Debt Insights Hub!';
  const paraFirst = 'Soon, you’ll be able to effortlessly track all your debts in one place, gaining powerful insights into each loan as well as your overall debt landscape.';
  const paraSecond = 'Whether you’re managing mortgages, credit cards, or personal loans, our new tool will show you how each debt impacts your financial journey - and provide a cumulative view alongside your investments.';
  const paraThird = 'Stay tuned as we help you achieve a more balanced, informed approach to building your net worth!';
  return (
    <ComingSoon heading={heading} paraFirst={paraFirst} paraSecond={paraSecond} paraThird={paraThird}/>
  );
}

export default Debts;