import React, { useState, useEffect, useCallback } from 'react';
import AsyncSelect from 'react-select/async';

const CustomSelect = ({ isDisabled, storageKey, ...props }) => {
  const [allOptions, setAllOptions] = useState([]);
  const [loadedOptions, setLoadedOptions] = useState([]);
  const [loadedCount, setLoadedCount] = useState(50); // Initial load count
  const [hasMoreOptions, setHasMoreOptions] = useState(true); // Track if more options are available

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem(storageKey)) || [];
    const mappedOptions = storedData.map((item) => ({
      value: item.id,
      label: item.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
    
    setAllOptions(mappedOptions);
    setLoadedOptions(mappedOptions.slice(0, 50)); // Load the first 50 options
    setHasMoreOptions(mappedOptions.length > 50); // Check if there are more options to load
  }, [storageKey]);

  const loadOptions = useCallback(
    (inputValue, callback) => {
      const filtered = allOptions.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      callback(filtered.slice(0, loadedCount));
    },
    [allOptions, loadedCount]
  );

  const handleLoadMoreOptions = () => {
    if (!hasMoreOptions) return; // Stop if no more options are available

    const newCount = loadedCount + 50;
    setLoadedCount(newCount);

    if (newCount >= allOptions.length) {
      setHasMoreOptions(false); // Mark that we’ve loaded all items
    }

    setLoadedOptions(allOptions.slice(0, newCount));
  };

  return (
    <div style={{ marginTop: '1em', marginBottom: '1em' }}>
      <AsyncSelect
        className="select-company-name"
        cacheOptions
        defaultOptions={loadedOptions} // Use dynamically loaded options
        loadOptions={loadOptions}
        onMenuScrollToBottom={handleLoadMoreOptions} // Trigger loading more
        isDisabled={isDisabled}
        {...props}
        styles={{
          placeholder: (base) => ({ ...base, color: 'rgb(214, 219, 225)' }),
        }}
      />
    </div>
  );
};

export default CustomSelect;
