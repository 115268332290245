import React, { useState, useEffect } from 'react';
import { fetchData } from '../../utils/helpers';
import { message } from 'antd';

import Loader from "../Loader";
import TransactionList from '../TransactionList';
import { apiConfig } from '../../ApiConfig';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';

function RealEstateTransaction() {

  const location = useLocation();
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [transactionList, setTransactionList] = useState([]);

  const url = apiConfig.REALESTATETRANSACTIONS;
  const method = 'GET';
  const localStorageKey = 'realEstateTransactionList';

  // Fetch asset data when the component mounts
  useEffect(() => {
    loadData();
  }, [url, method, localStorageKey]);

  const loadData = async (refresh = false) => {
      try {
          trackEvent('RealEstate Transactions', 'request', `Loading real estate transactions data`);
          const data = await fetchData(url, method, localStorageKey, refresh);
          setTransactionList(data.transactions_data);
          trackEvent('RealEstate Transactions', 'success', `Successfully real estate transactions data`);
      } catch (error) {
          message.error({
              content: `Error fetching transaction list: ${error.message}`,
              duration: 3, // Duration in seconds
          });
          trackEvent('RealEstate Transactions', 'error', `APi (${url}): ${error.message}`);
      } finally {
          setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  return (
    <div>
      {isLoading ? 
        ( <Loader /> ) 
        : (
          <div className="broker-main-container">
            {transactionList.length > 0 ? (
            <TransactionList data={transactionList} module={'realestate'}/>
          ) : (
            <p style={{textAlign: 'center'}}>No transactions available.</p> // Message for empty transactions
          )}
          </div>
        )}
    </div>
  );
}

export default RealEstateTransaction;
