import React, { createContext, useState, useEffect } from "react";
import { message } from "antd";
import { formatRupees } from "./utils/helpers";

// Create the context
export const ExchangeRateContext = createContext();

// Create the provider component
export const ExchangeRateProvider = ({ children }) => {
    const [exchangeRate, setExchangeRate] = useState(null);
    const [currency, setCurrency] = useState('INR');
    const [loadingExchangeRate, setLoadingExchangeRate] = useState(false);

    // Function to fetch and store exchange rate globally
    const fetchExchangeRate = async () => {
        setLoadingExchangeRate(true); // Start loading
        try {
            const response = await fetch("https://api.exchangerate-api.com/v4/latest/INR");
            const data = await response.json();
            const rate = data.rates.USD;

            setExchangeRate(rate); // Update global state with fetched exchange rate
            localStorage.setItem("exchangeRate", rate); // Store exchange rate in localStorage
        } catch (error) {
            message.error(`Error fetching exchange rate: ${error.message}`);
        } finally {
            setLoadingExchangeRate(false); // Stop loading after fetching rate
        }
    };

    // Function to load data from localStorage or fetch from API
    const loadData = () => {
        const storedRate = localStorage.getItem("exchangeRate");
        if (storedRate) {
            setExchangeRate(storedRate); // Set the exchange rate from localStorage
        } else {
            fetchExchangeRate(); // Fetch if not found in localStorage
        }
    };

    useEffect(() => {
        loadData(); // Load data on component mount
    }, []);

    // Helper function to convert amount based on exchange rate
    const convertCurrency = (amount, exchangeRate, targetCurrency) => {
        if (targetCurrency === 'USD') {
            return (parseFloat(amount.replace(/,/g, '')) * exchangeRate).toFixed(2);
        } else {
            return (parseFloat(amount) / exchangeRate).toFixed(2);
        }
    };

    // Function to toggle currency and update price format
    const handleCurrencyToggle = async (formData, setFormData, field) => {
        const targetCurrency = currency === 'INR' ? 'USD' : 'INR';

        if (targetCurrency === 'USD' && !exchangeRate) {
            await fetchExchangeRate(); // Ensure exchange rate is fetched
            if (!exchangeRate) {
                message.error('Unable to fetch exchange rate');
                return;
            }
        }

        const amount = formData[field] || '0'; // Dynamically use the field for amount
        const convertedAmount = convertCurrency(amount, exchangeRate, targetCurrency);

        setFormData((prevData) => ({
            ...prevData,
            [field]: targetCurrency === 'INR' ? formatRupees(convertedAmount) : convertedAmount,
        }));

        setCurrency(targetCurrency);
    };

    return (
        <ExchangeRateContext.Provider value={{ exchangeRate, fetchExchangeRate, loadingExchangeRate, currency, handleCurrencyToggle, convertCurrency}}>
            {children}
        </ExchangeRateContext.Provider>
    );
};
