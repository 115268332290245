// Import necessary modules and components from React and other files
import React, { useState, useEffect } from "react";
import TotalPortfolioValue from "../TotalPortfolioValue"; // Import the TotalPortfolioValue component
import RealEstateForm from "./RealEstateForm"; // Import the RealEstateForm component
import DynamicTabs from "../DynamicTabs";
import '../../css/MutualFund.css'; // Import CSS styles for the MutualFund component
import Loader from "../Loader"; // Import the Loader component
import EmptyState from "../EmptyState"; // Import the EmptyState component
import { apiConfig } from "../../ApiConfig";
import { fetchData, removeLocalStorageItem, apiRequest } from "../../utils/helpers";
import { message } from "antd";
import RealEstateList from "../RealEstateList";
import RealEstateAnalytics from "./RealEstateAnalytics";
import RealEstateTransaction from "./RealEstateTransaction";

import { trackEvent, trackPageView } from "../../utils/googleAnalytics";
import { useLocation } from "react-router-dom";

function RealEstate() {

    const location = useLocation();  // Get the current location
    useEffect(() => {
        // Track page view whenever the location changes
        trackPageView(location.pathname);
    }, [location]);  // Depend on location to re-run on route change

    const [isLoading, setIsLoading] = useState(true);  // Loading state
    const [isModalOpen, setIsModalOpen] = useState(false);  // Modal open state
    const [selectedData, setSelectedData] = useState(null);  // Selected data for editing

    const [portfolioValue, setPortfolioValue] = useState({});
    const [propertyList, setPropertyList] = useState([]);
    const [portfolioValue1DChange, setPortfolioValue1DChange] = useState({}); 
    const [portfolioValueReturns, setPortfolioValueReturns] = useState({}); 

    const url = apiConfig.REALESTATESUMMARY;
    const method = 'GET';
    const localStorageKey = 'realEstateList';

    // Fetch asset data when the component mounts
    useEffect(() => {
        loadData();
    }, [url, method, localStorageKey]);
    
    const loadData = async (refresh = false) => {
        try {
            trackEvent('RealEstate', 'request', 'Loading real estate data');
            const data = await fetchData(url, method, localStorageKey, refresh);
            if (!data) {
                setPortfolioValue(null);
                setPropertyList([]);
                setPortfolioValue1DChange(null);
                setPortfolioValueReturns(null);
            }else {
                // Set state with actual data
                setPortfolioValue(data);
                setPropertyList(data.asset_details || []);
                setPortfolioValue1DChange(data['1d_change'] || [0, 0]);
                setPortfolioValueReturns(data['returns'] || [0, 0]);
                trackEvent('RealEstate', 'success', 'Successfully loaded real estate data');
            }
        } catch (error) {
            message.error({
                content: `Error fetching property list: ${error.message}`,
                duration: 3, // Duration in seconds
            });
            trackEvent('RealEstate', 'error', `Error: ${error.message}`);
        } finally {
            setIsLoading(false); // Set loading to false after data is fetched or an error occurs
        }
    };

    const handleRefresh = () => {
        setIsLoading(true)
        loadData(true);
        removeLocalStorageItem('realEstateList');
        removeLocalStorageItem('realEstateAnalyticsData');
        removeLocalStorageItem('realEstateTransactionList');
        removeLocalStorageItem('REALESTATECURRVAL');
    };

    const portfolioData = {
        module: 'realestate',
        value: portfolioValue?.curr_val || 0,  // Use optional chaining and a fallback value
        // dayChangeValue: portfolioValue1DChange?.[0] || 0,  // Optional chaining with fallback
        percentage: portfolioValue1DChange?.[1] || 0,
        invested: portfolioValue?.investment || 0,
        returns: portfolioValueReturns?.[0] || 0,
        returnspercent: portfolioValueReturns?.[1] || 0,
        plusIcon: true,
    };    

    const tabs = [
        { key: 'propertylist', name: 'My Property', component: RealEstateList },
        { key: 'analytics', name: 'Analytics', component:  RealEstateAnalytics},
        { key: 'transactions', name: 'Transactions', component: RealEstateTransaction },
    ];
    // Function to open the modal, optionally with data for editing
    const openModal = (property = null) => {
        setSelectedData(property); // Set the selected data
        setIsModalOpen(true); // Open the modal
        trackEvent('RealEstate', 'openForm', 'Opening form modal');
    };

    // Function to close the modal
    const closeModal = () => setIsModalOpen(false);

    // Callback function to handle form submission
    const handleFormSubmit = async (formData, id = false) => {
        // formData = { transactions: formData };
        try {
            const url = (id) ? `${apiConfig.ADDPROPERTY}/${id}` : apiConfig.ADDPROPERTY; // Replace with your actual API URL
            const method = (id) ? 'PUT' : 'POST';

            trackEvent('RealEstate', 'request', `Submitting ${method} real estate property`);
            // Call the API to post the form data
            const result = await apiRequest(url, method, formData);
            if (result.statusCode === 200){
                message.success({
                    content: result.message,
                    duration: 5,
                })
                handleRefresh();
                closeModal(); // Close the modal after submission
                trackEvent('RealEstate', 'success', `Successfully ${method} real estate property`);
            } if (result.error) {
                message.error({
                  content: result.error.message || 'An error occurred',
                  duration: 8, // Duration in seconds
                });
                trackEvent('RealEstate', 'error', `APi (${url}): ${result.error.message}`);
            } else if(result.statusCode !== 200) {
                message.error({
                    content: `${result.message}`,
                    duration: 8, // Duration in seconds
                });
                trackEvent('RealEstate', 'error', `APi (${url}): ${result.message}`);
            }
        } catch (error) {
            message.error({
                content: `${error.message}`,
                duration: 8, // Duration in seconds
            });
            trackEvent('RealEstate', 'error', `APi (${url}): ${error.message}`);
        }
    };

    // Render the component
    return (
        <div className="container real-estate pad-right-unset pad-left-unset">
            {/* Show loader if isLoading is true */}
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {/* Check if there are any real estate properties */}
                    {propertyList.length === 0 ? (
                        // If no real estates, show the empty state
                        <EmptyState openModal={openModal} />
                    ) : (
                        <>
                        <TotalPortfolioValue data={portfolioData} onRefresh={handleRefresh} module={'Real Estate'} openForm={() => openModal(null)} />
                        <DynamicTabs tabs={tabs} data={propertyList} module={'real-estate'}/>
                
                        <div className="float-container">
                            <a className="float"  onClick={(e) => { e.preventDefault(); openModal(); }}>
                                <i className="fa fa-plus my-float"></i>
                            </a>
                        </div>
                        </>
                    )}
                </>
            )}

            {/* Conditionally render the RealEstateForm component if there is selected data */}
            {isModalOpen && (
                <RealEstateForm
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                    onFormSubmit={handleFormSubmit}
                    filledData={selectedData}
                />
            )}
        </div>
    );
}

// Export the RealEstate component as the default export
export default RealEstate;
