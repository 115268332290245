import React from "react";
import { formatRupees, removeSign } from "../utils/helpers";
import PieChart from "./PieChart";
import { Col } from "antd";

function HoldingSummary ({ data, pieChartData }) {
    const profitSummary = data.profit_summary;
    const lossSummary = data.loss_summary;
    return (
        <div className='holding-summary-main'>
            <Col className="col-md-12">
                <div className='holding-summary-container'>
                    <div className='holding-summary-row'>
                        <div className='holding-summary-pie-chart'>
                            <PieChart pieData={pieChartData} id='chart1' width={175} height={175} heading={''} status={false} profitLossColor={true}/>
                        </div>
                        <div className='holding-summary-flex'>
                            <div className=''>
                                {/* <div><span className='text-bold'>5</span> of 6 <span className='text-bold'>In Profit</span></div> */}
                                <div><span className='text-bold'>{profitSummary.info}</span></div>
                                <div style={{display: 'flex'}}>
                                    <span className='text-bold'>₹{formatRupees(Math.floor(profitSummary.total_profit[0])) || 0}&nbsp;&nbsp; </span>
                                    <span className='profit-color'>▲{removeSign(profitSummary.total_profit[1]) || 0}%</span>
                                </div>
                            </div>
                            <div className='border'></div>
                            <div className=''>
                                <div><span className='text-bold'>{lossSummary.info}</span></div>
                                <div className="holding-abs-per-val">
                                    <span className='text-bold'>₹{formatRupees(Math.floor(lossSummary.total_loss[0])) || 0} </span>
                                    <span className='loss-color' style={{display: 'flex'}}><span className='rotate-text'>▲</span>{removeSign(lossSummary.total_loss[1]) || 0}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='holding-summary-flex profit-loss-container'>
                        <div className='holding-summary-flex width-401'>
                        <h2 className='heading'>Highest Profit</h2>
                        <div>{data.highest_profit.name || 'N/A'}</div>
                        <div style={{display: 'flex'}}>
                            <span className='text-bold'>₹{formatRupees(Math.floor(data.highest_profit.returns[0])) || 0}&nbsp;&nbsp; </span>
                            <span className='profit-color'>▲{removeSign(data.highest_profit.returns[1]) || 0}%</span>
                        </div>
                        </div>
                        <div className='vertical-border'></div>
                        <div className='holding-summary-flex width-401'>
                            <h2 className='heading'>Highest Loss</h2>
                            <div>{data.highest_loss.name || 'N/A'}</div>
                            <div className="holding-abs-per-val">
                                <span className='text-bold'>₹{formatRupees(Math.floor(data.highest_loss.returns[0])) || 0} </span>
                                <span className='loss-color' style={{display: 'flex'}}><span className='rotate-text'>▲</span>{removeSign(data.highest_loss.returns[1]) || 0}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col className="col-md-3"></Col>
        </div>
    );
}

export default HoldingSummary ;