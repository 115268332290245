import React from "react";
//import '../css/comingsoon.css';
import { useNavigate } from "react-router-dom";

function ComingSoon({heading, paraFirst, paraSecond, paraThird}) {
    const navigate = useNavigate();
    return (
      <div className="container">
        <div className="wrapper wrapper-background">
          <div className="content">
            <h3>{heading}</h3>
            <p>
            {paraFirst}
            <br /><br />
            {paraSecond}
            <br /><br />
            {paraThird}
            </p>
            <a onClick={() => navigate('/assets')} className="btn1">Back to Home</a>
          </div>
        </div>
      </div>
    );
}

export default ComingSoon;