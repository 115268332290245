// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form } from 'react-bootstrap'; // Import Modal, Button, Form components from React Bootstrap
import Select from 'react-select'; // Import react-select for searchable dropdown
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { message } from "antd";
import { formatRupees, formatDateToInputValue, fetchData, ConvertDateToMilliseconds } from "../../utils/helpers";
import { apiConfig } from '../../ApiConfig';
import FileUpload from '../FileUpload';

import CustomSelect from '../CustomSelect';

import { trackEvent } from '../../utils/googleAnalytics';
// Define the IndiaStock Form functional component
const IndiaStocksForm = ({ isOpen, closeModal, onFormSubmit, initialFormData, defaultForm, onEdit }) => {
  // State for form validation and form data for both forms
    const [activeForm, setActiveForm] = useState(defaultForm); // State to toggle between forms
    const [formData, setFormData] = useState({ ...initialFormData });
    const [allCompanies, setAllCompanies] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [validated, setValidated] = useState(false);
    const [addedCompanies, setAddedCompanies] = useState([]); // Array to store added companies
    const [selectedOption, setSelectedOption] = useState(null);
    const [transactionType, setTransactionType] = useState('buy'); // State for transaction type
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isDateFocused, setIsDateFocused] = useState(false);
    // Access currency, exchangeRate, and currency toggle function from context
    
    const url = apiConfig.INDIASTOCKDIRECTORY;
    const method = 'GET';
    const localStorageKey = 'indiastockdirectory';

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (isOpen) {
            setFormData({
                id: '',
                quantity: '',
                stockprice: '',
                date: '',
            });
            setSelectedOption(null); // Reset react-select
            setValidated(false);
            setIsFormDirty(false);
            setAddedCompanies([]);
            setTransactionType('buy'); // Reset transaction type
        }
    }, [isOpen]);

    const loadData = async (refresh = false) => {
        try {
            trackEvent('IndiaStocks', 'request', 'Requesting india stocks directory');
            const data = await fetchData(url, method, localStorageKey, refresh);
            setAllCompanies(data);
            trackEvent('IndiaStocks', 'success', 'Successfully india stocks directory');
        } catch (error) {
            message.error(`Error fetching rsus list: ${error.message}`);
        }
    };

    const companyOptions = allCompanies.map(company => ({
        value: company.id,
        label: company.name,
    }));

    useEffect(() => {
        if (!isOpen) return;
    
        const selectedCompany = companyOptions.find(option => option.value === initialFormData.id);
        setFormData({
            ...initialFormData,
            date: initialFormData.date ? formatDateToInputValue(initialFormData.date) : "",
        });
        setSelectedOption(selectedCompany || null);
        setTransactionType(initialFormData.type)
        setValidated(false);
        setIsFormDirty(false);
    }, [isOpen, JSON.stringify(companyOptions), JSON.stringify(initialFormData)]);
    

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let newValue = value;

        if (id === "stockprice") {
            newValue = value.replace(/,/g, "");
            newValue = formatRupees(newValue);
        }

        setFormData((prevData) => ({ ...prevData, [id]: newValue }));
        setIsFormDirty(true);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsButtonLoading(true);
        const form = event.currentTarget;
    
        // Check if form is valid
        if (form.checkValidity() === false && addedCompanies.length === 0) {
            event.stopPropagation();
            setValidated(true);
            setIsButtonLoading(false);
        } else {
            // Additional validation for required fields
            if (addedCompanies.length === 0 && (!formData.id || !formData.quantity || !formData.stockprice || !formData.date)) {
                setValidated(true); // If the form is blank and nothing has been added, show validation error
                setIsButtonLoading(false);
                return;
            }            
    
            try {
                const { name, ...rest } = formData; // Destructure to exclude name and capture companyid
                // If there is data in the form, add it to the added companies list
                const updatedFormData = { ...rest, date: ConvertDateToMilliseconds(formData.date), type: transactionType };
    
                let finalAddedCompanies = [...addedCompanies];

                // Remove the 'name' field directly from the existing addedCompanies
                finalAddedCompanies = finalAddedCompanies.map(company => {
                    const { name, ...companyWithoutName } = company; // Exclude 'name' from existing currencies
                    return companyWithoutName; // Return the updated company object without the 'name'
                });

                if (formData.id && formData.quantity && formData.stockprice) {
                    // Merge the form data only if it's not blank
                    finalAddedCompanies = [...finalAddedCompanies, updatedFormData];
                }
                // Call the callback function with the entire list of added companies
                await onFormSubmit(finalAddedCompanies);
    
                // Clear the form after successful submission
                setFormData({
                    name: '',
                    id: '',
                    quantity: '',
                    stockprice: '',
                    date: '',
                });
                setValidated(false); // Reset validation state
                setTransactionType(transactionType); // Reset transaction type
            } catch (error) {
                console.error("Error:", error);
            } finally {
                setIsButtonLoading(false);
            }
        }
    };

    const handleAddMore = (event) => {
        event.preventDefault();
        if (!selectedOption || !formData.id || !formData.quantity || !formData.stockprice || !formData.date) {
            setValidated(true); // Validate the form fields
            return;
        }
        // Prepare the new company data
        let newCompanyData = {
            ...formData,
            name: selectedOption.label,
            date: ConvertDateToMilliseconds(formData.date),
            type: transactionType,
        };

        // Set the updated companies list, including the new company
        setAddedCompanies([...addedCompanies, newCompanyData]);
        
        // Reset the form fields
        setFormData({
            id: '',
            quantity: '',
            stockprice: '',
            date: '',
        });
        // Reset the select input
        setSelectedOption(null); // Clear selected option
        setTransactionType('buy'); // Reset transaction type
        setValidated(false); // Reset validation
        setIsFormDirty(false); // Mark form as clean
    };
    
    const handleDelete = (index) => {
        // Remove the item from the list
        setAddedCompanies(addedCompanies.filter((_, i) => i !== index));
    };
    
    
    const handleEdit = (index) => {
        // Find the item to edit
        const itemToEdit = addedCompanies[index];
        // Find the corresponding company option
        const selectedCompany = companyOptions.find(option => option.value === itemToEdit.id);
        // Format the date for the input field
        const formattedDate = itemToEdit.date ? formatDateToInputValue(itemToEdit.date) : "";
        // Fill the form with the selected item's data for editing
        setFormData({
            ...itemToEdit,
            id: itemToEdit.id,
            date: formattedDate, // Set the formatted date
            stockprice: itemToEdit.stockprice
        });
        
        setSelectedOption(selectedCompany); // Set the selected option for react-select
        setTransactionType(itemToEdit.type);
        // Remove the item from the list for now
        handleDelete(index);
    };
    
    // Dropdown options for transaction type
    const transactionOptions = [
        { value: 'buy', label: 'Buy' },
        { value: 'sell', label: 'Sell' },
    ];

  // Render the form modal
  return (
    <Modal show={isOpen} onHide={closeModal} backdrop="static">
        <Modal.Header closeButton>
            <Modal.Title>{onEdit ? 'Update' : 'Add'} transactions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* Toggle Buttons */}
            <div className="toggle-button-group">
            <button
                className={`toggle-button ${activeForm === 'manual' ? 'active' : ''} ${onEdit && activeForm !== 'manual' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('manual')}
                disabled={onEdit}
            >
                Manual
            </button>
            <button
                className={`toggle-button ${activeForm === 'upload' ? 'active' : ''} ${onEdit && activeForm !== 'upload' ? 'disable' : ''}`}
                onClick={() => !onEdit && setActiveForm('upload')}
                disabled={onEdit}
            >
                Upload File
            </button>
            </div>

            {activeForm === 'manual' ? (
            <Form noValidate validated={validated} onSubmit={handleSubmit} className="us-stock">
                <Form.Group controlId="transactionType">
                    <Select
                        className='select-company-name'
                        options={transactionOptions}
                        placeholder="Select Transaction Type"
                        onChange={(option) => setTransactionType(option ? option.value : 'buy')} // Update transaction type
                        value={transactionOptions.find(option => option.value === transactionType) || transactionOptions.find(option => option.value === setTransactionType('buy'))}
                        isSearchable
                        isDisabled={onEdit}
                    />
                </Form.Group>
                <Form.Group controlId="name">
                    {onEdit ? (
                        <Form.Control
                            type="text"
                            placeholder="Select Company Name"
                            value={selectedOption ? selectedOption.label : ""}
                            autoComplete="off"
                            disabled={true}
                        />
                    ) : (
                        <CustomSelect
                            placeholder="Select Company Name *"
                            storageKey="indiastockdirectory" // or any other key you want to pass
                            value={selectedOption}
                            onChange={(option) => {
                                setSelectedOption(option);
                                setFormData({ ...formData, id: option ? option.value : '' });
                            }}
                            isSearchable
                            isDisabled={onEdit}
                        />
                    )}

                    {validated && !selectedOption && (
                        <div className="invalid-feedback" style={{ display: 'block' }}>
                            Please select the company name.
                        </div>
                    )}
                </Form.Group>

                <Form.Group controlId="quantity">
                    <Form.Control
                        type="number"
                        placeholder="No. Of Stocks *"
                        value={formData.quantity}
                        autoComplete="off"
                        onChange={handleInputChange}
                        required
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter the no. of stocks.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="stockprice">
                    <div className="input-container">
                        <span className="input-label">₹</span>
                        <Form.Control
                            type="text"
                            placeholder={`${transactionType === 'buy' ? 'Buy' : 'Sell'} Price Per Stock *`}
                            value={formData.stockprice}
                            autoComplete="off"
                            required
                            onChange={handleInputChange}
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the price per stock.
                        </Form.Control.Feedback>
                    </div>
                </Form.Group>

                <Form.Group controlId="date">
                    <div className={`position-relative ${!formData.date ? 'placeholder-date' : ''}`}>
                    {!formData.date && !isDateFocused && (
                        <span className='date-placeholder'>
                        Investment Date *
                        </span>
                    )}
                    <Form.Control
                        type="date"
                        value={formData.date}
                        onChange={handleInputChange}
                        autoComplete="off"
                        placeholder="Investment Date *"
                        required
                        style={{ width: '100%', cursor: 'pointer' }}
                        onFocus={(e) => {
                            if (!formData.date) {
                                setIsDateFocused(true); // Only set focus state if date is not filled
                                e.target.showPicker();  // Show the picker when focused
                            }
                        }}
                        onBlur={() => setIsDateFocused(false)} // Show placeholder on blur if no date
                    />
                    <Form.Control.Feedback type="invalid">
                        Please select the date.
                    </Form.Control.Feedback>
                    </div>
                </Form.Group>
                {!onEdit && (
                    <>
                        <a className="add-more" onClick={handleAddMore}>
                        Add More
                        </a>
                        {addedCompanies.length > 0 && (
                        <div className="added-companies-list">
                            <table className="companies-table">
                            <thead>
                                <tr>
                                <th className="table-head-name">Name</th>
                                <th>Qty</th>
                                <th>Amount</th>
                                <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {addedCompanies.map((company, index) => (
                                <tr key={index} className={company.type}>
                                    <td className="table-row-name">
                                    <div className="name-wrapper">
                                        <span>{company.name}</span>
                                    </div>
                                    </td>
                                    <td>
                                    <span>{company.quantity}</span>
                                    </td>
                                    <td>
                                    <span>
                                        ₹
                                        {company.stockprice}
                                    </span>
                                    </td>
                                    <td>
                                    <span>
                                        <i
                                        className="fas fa-edit edit-company"
                                        onClick={() => handleEdit(index)}
                                        ></i>
                                        <i
                                        className="fas fa-trash delete-company"
                                        onClick={() => handleDelete(index)}
                                        ></i>
                                    </span>
                                    </td>
                                </tr>
                                ))}
                            </tbody>
                            </table>
                        </div>
                        )}
                    </>
                )}
                <Form.Group controlId="indiaStocksSubmit" className="btn-container">
                    <Button variant="primary" type="submit" disabled={isButtonLoading || (onEdit && !selectedOption)}>
                    {isButtonLoading ? (onEdit ? 'Updating...' : 'Saving...') : (onEdit ? 'Update' : 'Save')}
                    </Button>
                </Form.Group>
            </Form>
            ) : (
                <FileUpload onFileSubmit={onFormSubmit} />
            )}
        </Modal.Body>
    </Modal>
  );
};

export default IndiaStocksForm;
