import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { message } from "antd";
import { fetchData } from '../../utils/helpers';
import { apiConfig } from '../../ApiConfig';

import PieChart from '../PieChart';
import ApexChart from '../ApexChart';
import Analytics from '../Analytics';
import Loader from '../Loader'; // Import the loader component

import DynamicTable from '../DynamicTable';

import { trackEvent, trackPageView } from '../../utils/googleAnalytics';
import { useLocation } from 'react-router-dom';

function PreciousMetalsAnalytics() {

  const location = useLocation();  // Get the current location
  useEffect(() => {
      // Track page view whenever the location changes
      trackPageView(location.pathname);
  }, [location]);  // Depend on location to re-run on route change

  const url = apiConfig.PRECIOUSMETALSANALYTICS;
  const method = 'GET';
  const localStorageKey = 'preciousMetalsAnalyticsData';

  const [analyticsValues, setAnalyticsValues] = useState({}); 
  const [distribution, setDistribution] = useState([]);
  const [holdingInsights, setHoldingInsights] = useState({}); 

  const [baseDailyTrend, setBaseDailyTrend] = useState({}); 
  const [baseWeeklyTrend, setBaseWeeklyTrend] = useState({}); 


  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null);

  // Fetch asset data when the component mounts
  useEffect(() => {
    const loadData = async () => {
      try {
        trackEvent('PreciousMetals Analytics', 'request', 'Loading precious metals analytics data');
        const data = await fetchData(url, method, localStorageKey);
        updateStateWithData(data);
        trackEvent('PreciousMetals Analytics', 'success', 'Successfully precious metals analytics data');
      } catch (error) {
        message.error({
          content: `Error fetching analytics data: ${error.message}`,
          duration: 3, // Duration in seconds
        });
        setError(`Error fetching analytics data: ${error.message}`);
        trackEvent('PreciousMetals Analytics', 'error', `APi (${url}): ${error.message}`);
      } finally {
        setIsLoading(false); // Set loading to false after data is fetched or an error occurs
      }
  };

  loadData();
  }, [url, method, localStorageKey]);
  
  const updateStateWithData = (result) => {
    // Assuming the API returns an object with asset values
    setAnalyticsValues(result);
    setDistribution(result.category_distribution);
    setHoldingInsights(result.holdings_insights);
    

    const baseDailyTrend = result.historic_trend.base_trend.daily_data;
    setBaseDailyTrend(baseDailyTrend);
    const baseWeeklyTrend = result.historic_trend.base_trend.weekly_data;
    setBaseWeeklyTrend(baseWeeklyTrend);

    trackEvent('PreciousMetals Analytics', 'success', 'Loaded precious metals analytics data');
  }

  const initialRows = [
    {
        id: 'row1',
        columns: [
            { id: '1', header: 'Portfolio Growth Trend', contentId: 'historic-trend' },
        ],
    },
    {
        id: 'row2',
        columns: [
            { id: '2', header: 'Portfolio Performance by Company Holdings', contentId: 'portfolio-performance'}
        ]   
    },
    {
      id: 'row3',
      columns: [
        { id: '3', header: 'Category Distribution', contentId: 'category-distribution'},
        { id: '4', header: '', contentId: ''}
      ],
  },
  ];

  const contentMap = useMemo(() => ({
    'historic-trend' : <div className='holding-chart-container'>
                          <ApexChart baseTrend={baseDailyTrend} referenceTrend={false} baseWeeklyTrend={baseWeeklyTrend} referenceWeeklyTrend={false} referenceIndex={false}/>
                        </div>,
    ...(distribution?.length > 0 && {
      'category-distribution' : <PieChart pieData={distribution} id='chart-1' width='480' height='250' />,
    }),
    'portfolio-performance' : <div className="piechart-wrapper">
            <DynamicTable tableData={holdingInsights.change}/>
            </div>,
   }), [analyticsValues, distribution, baseDailyTrend, baseWeeklyTrend]);
  
  return (
    <div className='realesate-analytics'>
      {isLoading ? (
          <Loader /> // Show the loader while data is loading
      ) : error ? (
        <p style={{textAlign: "center", marginTop: '1rem'}}>{error}</p>
      ) : (
        <Analytics initialRows={initialRows} contentMap={contentMap} module={'realesate-analytics'}/>
      )}
    </div>
  );
}

export default PreciousMetalsAnalytics;
