import React, { useState, useEffect, useRef } from 'react';
import '../css/Calculator.css';
import SliderWithInput from './SliderWithInput';
import CalculatorPieChart from './CalculatorPieChart';
import Select from 'react-select'; // Import react-select for searchable dropdown
import { formatRupees } from '../utils/helpers';
import HeaderNoValidation from '../components/HeaderNoValidation';

import CalculatorGraph from './CalculatorGraph';
import CalculatorTable from './CalculatorTable';
import SIPLumpsum from './SIPLumpsum';

import { useAuth } from '../AuthContext';
import Header from '../components/Header';
// Functional component for Retirement Calculator
const SIPCalculator = () => {
    const {isAuthenticated} = useAuth();
    const [activeTab, setActiveTab] = useState('SIP');
    const tabsContainerRef = useRef(null);
    // State variables for the calculator inputs
    const [monthlyInvestment, setMonthlyInvestment] = useState(10000); // Monthly amount
    const [durationInYear, setDurationInYear] = useState(10);
    const [durationInMonth, setDurationInMonth] = useState(0); // Duration in month
    const [returnRate, setReturnRate] = useState(12); // Return rate
    const [totalInvested, setTotalInvested] = useState(0);
    const [growthAmount, setGrowthAmount] = useState(0);
    const [futureValue, setFutureValue] = useState(0); 
    const [stepUpMethod, setStepUpMethod] = useState(false);
    const [stepUp, setStepUp] = useState(10);
    const [inflationFlag, setInflationFlag] = useState(false);
    const [inflationRate, setInflationRate] = useState(6);
    const [graphData, setGraphData] = useState([]);

    const chartLabel = ['Total Future Value', 'Principal Amount'];
    const tooltipLabel = ['Principal Amount', 'Total Future Value'];

    const [afterRetirementFlowType, setAfterRetirementFlowType] = useState('graph');
    

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    useEffect(() => {
        const tabsContainer = tabsContainerRef.current;
        if (tabsContainer) {
        const tab = tabsContainer.children[activeTab];
        const { offsetLeft, offsetWidth } = tab;
        const clipRight = offsetLeft + offsetWidth;
        const containerWidth = tabsContainer.offsetWidth;

        tabsContainer.style.setProperty(
            '--clip-path',
            `inset(0 ${Number(100 - (clipRight / containerWidth) * 100).toFixed()}% 0 ${Number((offsetLeft / containerWidth) * 100).toFixed()}% round 17px)`
        );
        }
    }, [activeTab]);
    
    // Function to calculate Future Value (FV)
    const calculateFV = () => {
        const rateMonthly = returnRate / 100 / 12; // Convert to monthly rate
        let totalFutureValue = 0;
        let stepUpAmount = monthlyInvestment;
        const inflationRateMonthly = Math.pow(1 + inflationRate / 100, 1 / 12) - 1;
    
        // Loop for the total duration
        for (let month = 0; month < durationInMonth; month++) {
            // Calculate monthly investment growth with the step-up amount
            totalFutureValue = (totalFutureValue + stepUpAmount) * (1 + rateMonthly);
    
            // Adjust stepUpAmount by monthly inflation rate if inflationFlag is true
            if (inflationFlag) {
                stepUpAmount /= (1 + inflationRateMonthly);
            }
            const monthInYear = month % 12;
            // Check if it's the end of the year or the last month in the duration
            const isEndOfYear = monthInYear === 11;
            const isLastMonth = month === durationInMonth - 1;
    
            // Increase step-up amount at the end of each year if stepUpMethod is true
            if ((isEndOfYear || isLastMonth) && stepUpMethod) {
                stepUpAmount += Math.round((stepUpAmount * stepUp) / 100);
            }
        }
    
        return totalFutureValue; // Final accumulated value
    };    

    const calculateSIP = () => {
        let stepUpAmount = monthlyInvestment;
        let investedAmount = 0;
        const inflationRateMonthly = Math.pow(1 + inflationRate / 100, 1 / 12) - 1;
    
        // Loop through the total duration
        for (let month = 0; month < durationInMonth; month++) {
            investedAmount += stepUpAmount; // Accumulate invested amount
    
            // Adjust stepUpAmount by monthly inflation rate if inflationFlag is true
            if (inflationFlag) {
                stepUpAmount /= (1 + inflationRateMonthly);
            }
            const monthInYear = month % 12;
            // Check for end of year or last month in the duration
            const isEndOfYear = monthInYear === 11;
            const isLastMonth = month === durationInMonth - 1;
    
            if ((isEndOfYear || isLastMonth) && stepUpMethod) {
                // Increase step-up amount at the end of each year if stepUpMethod is true
                stepUpAmount += Math.round((stepUpAmount * stepUp) / 100);
            }
        }
    
        // Calculate future value and growth
        let futureVal, growth;
        if (returnRate === 0) {
            // If return rate is 0, future value equals invested amount
            futureVal = investedAmount;
            growth = 0;
        } else {
            // Use calculateFV to get the future value if return rate is non-zero
            futureVal = calculateFV(); // Assuming calculateFV is defined elsewhere
            growth = futureVal - investedAmount;
        }
    
        // Update state values
        setTotalInvested(Math.round(investedAmount));
        setFutureValue(Math.round(futureVal));
        setGrowthAmount(Math.round(growth));
    };
    
    
    // Function to calculate data for graph and table
    const calculateGraphTable = () => {
        const rateMonthly = returnRate / 100 / 12; // Convert to monthly rate
        const yearlyData = []; // Array to store year-wise data
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];
    
        let totalFutureValue = 0, totalMonthlyInvestment = 0, annualPrincipal = 0, stepUpAmount = monthlyInvestment;
        let currentYear = new Date().getFullYear();
        const inflationRateMonthly = Math.pow(1 + inflationRate / 100, 1 / 12) - 1;
    
        // Loop for the total duration
        for (let month = 0; month < durationInMonth; month++) {
            // Calculate monthly investment growth
            totalFutureValue = (totalFutureValue + stepUpAmount) * (1 + rateMonthly);
            totalMonthlyInvestment += stepUpAmount;
    
            // Adjust stepUpAmount by monthly inflation rate if inflationFlag is true
            if (inflationFlag) {
                stepUpAmount /= (1 + inflationRateMonthly);
            }
    
            // Calculate current year and month within year
            const year = Math.floor(month / 12);
            const monthInYear = month % 12;
    
            if (!yearlyData[year]) {
                // Initialize new year data
                yearlyData[year] = { 
                    year: currentYear + year, 
                    months: [],
                    yearlyInvestment: 0 
                };
            }
            // Store month-wise data within each year
            yearlyData[year].months.push({
                yearNo: year + 1,
                yearName: currentYear + year,
                month: monthNames[monthInYear],
                expense: Math.round(totalMonthlyInvestment),
                remainingCorpus: Math.round(totalFutureValue)
            });
    
            // Check if it's the last month of the year or the last month in the duration
            const isEndOfYear = monthInYear === 11;
            const isLastMonth = month === durationInMonth - 1;
    
            // Update yearly investment at the end of each year or duration
            if (isEndOfYear || isLastMonth) {
                // yearlyData[year].yearlyInvestment = Math.round(annualPrincipal + (stepUpAmount * (isEndOfYear ? 12 : monthInYear + 1)));
                yearlyData[year].yearlyInvestment = Math.round(totalMonthlyInvestment);
                annualPrincipal += stepUpAmount * (isEndOfYear ? 12 : monthInYear + 1);
                
                // Increase step-up amount at the end of each year if stepUpMethod is true
                if (stepUpMethod) {
                    stepUpAmount += Math.round((stepUpAmount * stepUp) / 100);
                }
            }
        }
    
        return yearlyData; // Return data structured for table or graph display
    };
    
    
    const calculatePieChartData = () => {
        let principalAmount = 0;
        let stepUpAmount = monthlyInvestment;
        const inflationRateMonthly = Math.pow(1 + inflationRate / 100, 1 / 12) - 1;
    
        for (let month = 0; month < durationInMonth; month++) {
            // Accumulate the adjusted principal amount over time
            principalAmount += stepUpAmount;
    
            // Adjust stepUpAmount by monthly inflation rate if inflationFlag is true
            if (inflationFlag) {
                stepUpAmount /= (1 + inflationRateMonthly);
            }
            const monthInYear = month % 12;
            // Check if it's the end of the year or the last month in the duration
            const isEndOfYear = monthInYear === 11;
            const isLastMonth = month === durationInMonth - 1;
    
            if ((isEndOfYear || isLastMonth) && stepUpMethod) {
                // Increase step-up amount at the end of each year
                stepUpAmount += Math.round((stepUpAmount * stepUp) / 100);
            }
        }
    
        // Calculate future value with the original function
        const futureVal = calculateFV();
    
        // Growth Amount = Future Value - Inflation-Adjusted Principal Amount
        const growthAmount = futureVal - principalAmount;
    
        // Return the results
        return [
            { label: "Principal Amount", count: Math.round(principalAmount) },
            { label: "Interest Amount", count: Math.round(growthAmount) }
        ];
    };
    
    // Generate the year-wise and month-wise data for the graph and table
    
    const pieChartData = calculatePieChartData();
    
    useEffect(() => {
        setDurationInMonth(durationInYear * 12);
    }, [durationInYear])
    useEffect(() => {
        calculateSIP();
        const data = calculateGraphTable();
        setGraphData(data)
    }, [
        monthlyInvestment,
        durationInMonth,
        returnRate,
        growthAmount,
        futureValue,
        stepUpMethod,
        stepUp,
        inflationFlag,
        inflationRate
    ]);

    const handleTabSelect = (type) => {
      setAfterRetirementFlowType(type);
    };

    const stepUpMethodOptions = [
        { value: false, label: 'Normal SIP' },
        { value: true, label: 'Step Up SIP' },
    ];

    const inflationOptions = [
        { value: false, label: 'No' },
        { value: true, label: 'Yes' },
    ];
    return (
        <>
            {isAuthenticated ? <Header /> : <HeaderNoValidation />}
            <div className='tab-container'>
                <div className="tab-content1">
                    {activeTab === 'SIP' ? (
                        <div className='retirement-container container sip-container' style={{ marginTop: 'unset' }}>
                            <div className='retirement-row'>
                                <div className='retirement-left' style={{paddingTop: 'unset'}}>
                                    <h1 className='retirement-heading'>SIP Calculator</h1>
                                    <div className="tabs-wrapper" style={{paddingTop: '1rem'}}>
                                        {['SIP', 'Lumpsum'].map((tabName) => (
                                            <div
                                                key={tabName}
                                                className={`tab ${activeTab === tabName ? 'active' : ''}`}
                                                onClick={() => handleTabClick(tabName)}
                                            >
                                                {tabName}
                                            </div>
                                        ))}
                                    </div>
                                    {/* SIP Content */}
                                    <div className='slider-input-container' style={{ marginBottom: '2.3rem' }}>
                                        <label className="slider-label">Method</label>
                                        <Select
                                            className='select-company-name m-top'
                                            options={stepUpMethodOptions}
                                            placeholder="Select Method"
                                            onChange={(option) => setStepUpMethod(option ? option.value : false)}
                                            value={stepUpMethodOptions.find(option => option.value === stepUpMethod) || stepUpMethodOptions.find(option => option.value === setStepUpMethod(false))}
                                        />
                                    </div>
                                    <SliderWithInput onChange={setMonthlyInvestment} label={'Monthly SIP Amount'} inputLabel={'₹'} defaultValue={monthlyInvestment} min={0} max={500000} />
                                    {stepUpMethod && 
                                    <SliderWithInput onChange={setStepUp} label={'Step Up (Yearly)'} inputLabel={'%'} defaultValue={stepUp} min={0} max={100} allowDecimal={true} />
                                    }
                                    <SliderWithInput onChange={setDurationInYear} label={'Time Period'} inputLabel={'Years'} defaultValue={durationInYear} min={0} max={50} allowDecimal={true} />
                                    <SliderWithInput onChange={setReturnRate} label={'Expected Return Rate (p.a)'} inputLabel={'%'} defaultValue={returnRate} min={0} max={20} />
                                    <div className='slider-input-container' style={{ marginBottom: '2.3rem' }}>
                                        <label className="slider-label">Calculate with Inflation</label>
                                        <Select
                                            className='select-company-name'
                                            options={inflationOptions}
                                            placeholder="Calculate with Inflation"
                                            onChange={(option) => setInflationFlag(option ? option.value : false)}
                                            value={inflationOptions.find(option => option.value === inflationFlag) || inflationOptions.find(option => option.value === setInflationFlag(false))}
                                        />
                                    </div>
                                    {inflationFlag && 
                                        <SliderWithInput onChange={setInflationRate} label={'Inflation Rate'} inputLabel={'%'} defaultValue={inflationRate} min={0} max={20} />
                                    }
                                </div>
                                <div className={`${stepUpMethod ? 'sip-stepUp' : ''} ${inflationFlag ? 'sip-inflation' : 'sip'} right-border`}></div>
                                <div className={`${stepUpMethod ? 'stepUp-right' : ''} ${inflationFlag ? 'sip-right-value-row' : ''} retirement-right`}>
                                    <CalculatorPieChart pieChartData={pieChartData} />
                                    <div>
                                        <div className='right-value-container'>
                                            <span className='value-title'>Total SIP Amount Invested:</span> 
                                            <span className='right-value'>₹ {formatRupees(totalInvested)}</span>
                                        </div>
                                        <div className='right-value-container'>
                                            <span className='value-title'>Total Interest:</span>
                                            <span className='right-value'>₹ {formatRupees(growthAmount)}</span>
                                        </div>
                                        <div className='right-value-container'>
                                            <span className='value-title'>Total Future Value:</span>
                                            <span className='right-value'>₹ {futureValue >= 0 ? formatRupees(futureValue) : '0'}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='retirement-row'>
                                <div className='show-table-graph-container'>
                                    <h4 className='graph-heading'>SIP Growth</h4>
                                    <div className="toggle-button-group">
                                        <button
                                            className={`toggle-button ${afterRetirementFlowType === 'graph' ? 'active' : ''}`}
                                            onClick={() => handleTabSelect('graph')}
                                        >
                                            Graph
                                        </button>
                                        <button
                                            className={`toggle-button ${afterRetirementFlowType === 'table' ? 'active' : ''}`}
                                            onClick={() => handleTabSelect('table')}
                                        >
                                            Table
                                        </button>
                                    </div>
                                    {afterRetirementFlowType === 'table' ? (
                                        <CalculatorTable data={graphData} heading2={'Principal Amount'} heading3={'Total Future value'} module={'sip'} />
                                    ) : (
                                        <CalculatorGraph data={graphData} chartLabel={chartLabel} tooltipLabel={tooltipLabel} module={'sip'} />
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <SIPLumpsum  onBackToSIP={() => handleTabClick('SIP')}/>
                    )}
                </div>
            </div>
        </>
    );
    
};

export default SIPCalculator;
