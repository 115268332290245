import './css/App.css';
import './css/Responsive.css';
import React, {useEffect} from 'react';
import Header from './components/Header';
import NoHeaderLayout from './NoHeaderLayout';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { initGA } from './utils/googleAnalytics';

import Login from './Login';
import Signup from './Signup';
import OTPForm from './OTPForm';
import ResetPassword from './ResetPassword';
import SetPassword from './SetPassword';

import { AuthProvider } from './AuthContext'; 
import ProtectedRoute from './ProtectedRoute';

import NetWorth from './components/NetWorth';
import Assets from './components/Assets';
import Debts from './components/Debts';
import MutualFund from './components/mutualfunds/MutualFund';
import IndiaStocks from './components/indiastocks/IndiaStocks';
import USStocks from './components/usstocks/USStocks';
import CryptoCurrency from './components/cryptocurrency/CryptoCurrency';
import PrivateEquity from './components/privateequity/PrivateEquity';
import RealEstate from './components/realestate/RealEstate';
import PreciousMetals from './components/preciousmetals/PreciousMetals';
import PreciousMetalsDetails from './components/preciousmetals/PreciousMetalsDetails';
import RetirementFunds from './components/retirementfunds/RetirementFunds';
import Others from './components/others/Others';
import BankFds from './components/bankfds/BankFds';
import USStocksInformation from './components/USStocksInformation';
import CompanyInformationn from './components/CompanyInformationn';
import CryptoInformation from './components/cryptocurrency/CryptoInformation';
import { ExchangeRateProvider } from './ExchangeRateContext';
import RetirementCalculator from './calculator/RetirementCalculator';
import SIPCalculator from './calculator/SIPCalculator';
import GrowthCalculator from './calculator/GrowthCalculator';
import MFInformation from './components/mutualfunds/MFInformation';
import IndiaStocksInformation from './components/indiastocks/IndiaStocksInformation';
import PrivateEquityInformation from './components/privateequity/PrivateEquityInformation';

function App() {

  useEffect(() => {
    // Initialize GA only once when the app is loaded
    initGA();
  }, []);  // Empty dependency array means it runs only once

  return (
    <AuthProvider>
      <ExchangeRateProvider>
        <Router>
          <div className="app-layout">
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Navigate to="/login" replace />} />
                {/* Routes without header */}
                <Route path="/login" element={<NoHeaderLayout><Login /></NoHeaderLayout>} />
                <Route path="/signup" element={<NoHeaderLayout><Signup /></NoHeaderLayout>} />
                <Route path="/verifyotp" element={<NoHeaderLayout><OTPForm /></NoHeaderLayout>} />
                <Route path="/resetpassword" element={<NoHeaderLayout><ResetPassword /></NoHeaderLayout>} />
                <Route path="/setpassword" element={<NoHeaderLayout><SetPassword /></NoHeaderLayout>} />
                
                {/* Route with header conditionally rendered */}
                <Route 
                  path="calculator/retirement" 
                  element={<NoHeaderLayout><RetirementCalculator /></NoHeaderLayout>} 
                />
                <Route 
                  path="calculator/sip" 
                  element={<NoHeaderLayout><SIPCalculator /></NoHeaderLayout>} 
                />
                <Route 
                  path="calculator/growth" 
                  element={<NoHeaderLayout><GrowthCalculator /></NoHeaderLayout>} 
                />

                {/* Protected routes */}
                <Route path="/networth" element={
                  <ProtectedRoute>
                    <Header />
                    <NetWorth />
                  </ProtectedRoute>
                } />
                <Route path="/assets" element={
                  <ProtectedRoute>
                    <Header />
                    <Assets />
                  </ProtectedRoute>
                } />
                <Route path="/debts" element={
                  <ProtectedRoute>
                    <Header />
                    <Debts />
                  </ProtectedRoute>
                } />
                <Route path="/assets/mutualfund" element={
                  <ProtectedRoute>
                    <Header />
                    <MutualFund />
                  </ProtectedRoute>
                } />
                <Route path="/assets/indiastocks" element={
                  <ProtectedRoute>
                    <Header />
                    <IndiaStocks />
                  </ProtectedRoute>
                } />
                <Route path="/assets/usstocks" element={
                  <ProtectedRoute>
                    <Header />
                    <USStocks />
                  </ProtectedRoute>
                } />
                <Route path="/assets/cryptocurrency" element={
                  <ProtectedRoute>
                    <Header />
                    <CryptoCurrency />
                  </ProtectedRoute>
                } />
                <Route path="/assets/privateequity" element={
                  <ProtectedRoute>
                    <Header />
                    <PrivateEquity />
                  </ProtectedRoute>
                } />
                <Route path="/assets/realestate" element={
                  <ProtectedRoute>
                    <Header />
                    <RealEstate />
                  </ProtectedRoute>
                } />
                <Route path="/assets/preciousmetals" element={
                  <ProtectedRoute>
                    <Header />
                    <PreciousMetals />
                  </ProtectedRoute>
                } />
                <Route path="/assets/preciousmetals/details/:id" element={
                  <ProtectedRoute>
                    <Header />
                    <PreciousMetalsDetails />
                  </ProtectedRoute>
                } />
                <Route path="/assets/indiastocks/information/:id" element={
                  <ProtectedRoute>
                    <Header />
                    <IndiaStocksInformation />
                  </ProtectedRoute>
                } />
                <Route path="/assets/usstocks/information/:id" element={
                  <ProtectedRoute>
                    <Header />
                    <USStocksInformation />
                  </ProtectedRoute>
                } />
                <Route path="/assets/mutualfund/information/:id" element={
                  <ProtectedRoute>
                    <Header />
                    <MFInformation />
                  </ProtectedRoute>
                } />
                <Route path="/assets/cryptocurrency/information/:id" element={
                  <ProtectedRoute>
                    <Header />
                    <CryptoInformation />
                  </ProtectedRoute>
                } />
                <Route path="/assets/privateequity/information/:name" element={
                  <ProtectedRoute>
                    <Header />
                    <PrivateEquityInformation />
                  </ProtectedRoute>
                } />
                <Route path="/assets/retirementfunds" element={
                  <ProtectedRoute>
                    <Header />
                    <RetirementFunds />
                  </ProtectedRoute>
                } />
                <Route path="/assets/others" element={
                  <ProtectedRoute>
                    <Header />
                    <Others />
                  </ProtectedRoute>
                } />
                <Route path="/assets/fds" element={
                  <ProtectedRoute>
                    <Header />
                    <BankFds />
                  </ProtectedRoute>
                } />
                {/* Add more routes as needed */}
              </Routes>
            </div>
          </div>
        </Router>
      </ExchangeRateProvider>
    </AuthProvider>
  );
}

export default App;
