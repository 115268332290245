import styled from "styled-components";

export const Header = styled.header`
	.input-nav {
		margin-left:5rem !important;
		width:25rem;
	}

	.ml-auto {
		display: flex;
		align-items: center;
		gap: 10rem;
	}
	
	.menu-container {
		display: flex;
    	gap: 4rem;
		align-items: center;
	}
	
	.menu-container a {
		text-decoration: unset;
    	color: #000000;
	}
	
	.custom-item.dropdown-item {
		background-color: unset;
	}
	.custom-dropdown-toggle {
		color: inherit; /* Inherit color from parent or set a custom color */
		background: none; /* No background */
		border: none; /* No border */
		padding: 0; /* Remove default padding */
		font-size: 16px; /* Adjust font size */
		cursor: pointer;
		display: flex;
		align-items: center;
		text-decoration: none;
	}
	.custom-dropdown-toggle:first-child:active {
		color: #000000;
	}

	.custom-dropdown-toggle::after {
	display: none; /* Remove default caret */
	}
	.custom-dropdown-menu {
		transform: translate3d(0px, 40px, 0px) !important
	}
	.custom-item:active {
		background-color: #f8f9fa;
	}

	@media(max-width:920px) {
		.input-nav {
			display:none;
		}
	}
`;