import React, { useState, useEffect } from 'react';
import '../css/Calculator.css';
import SliderWithInput from './SliderWithInput';
import CalculatorPieChart from './CalculatorPieChart';
import CalculatorTable from './CalculatorTable';
import { formatRupees } from '../utils/helpers';
import HeaderNoValidation from '../components/HeaderNoValidation';

import CalculatorGraph from './CalculatorGraph';

import { useAuth } from '../AuthContext';
import Header from '../components/Header';

// Functional component for Retirement Calculator
const RetirementCalculator = () => {

    const {isAuthenticated} = useAuth();
    // Function to calculate Future Value (FV)
    const calculateFV = (rate, nper, pmt, pv, type = 0) => {
        const monthlyRate = rate / 100 / 12; // Convert annual rate to monthly rate
        let fv;
        if (monthlyRate === 0) {
            fv = pv; // If rate is 0, FV is just the present value
        } else {
            // Formula to calculate future value using the present value, payment, rate, and number of periods
            fv = -(pmt * ((Math.pow(1 + monthlyRate, nper) - 1) / monthlyRate) * (1 + monthlyRate * type)) - pv * Math.pow(1 + monthlyRate, nper);
        }
        return fv; // Return the calculated future value
    };

    // Function to calculate Present Value (PV)
    const calculatePV = (rate, nper, pmt, fv, type = 0) => {
        const monthlyRate = rate / 100 / 12; // Convert annual rate to monthly rate
        let pv;
        if (monthlyRate === 0) {
            pv = fv; // If rate is 0, PV is just the future value
        } else {
            // Formula to calculate present value using future value, payments, rate, and number of periods
            pv = -(-fv + (pmt * ((Math.pow(1 + monthlyRate, nper) - 1) / monthlyRate) * (1 + monthlyRate * type))) / Math.pow(1 + monthlyRate, nper);
        }
        return pv; // Return the calculated present value
    };

    // Function to calculate the (PMT)
    const calculatePMT = (rate, nper, fv, type = 1) => {
        const monthlyRate = rate / 100 / 12; // Convert annual rate to monthly rate
        let pmt;
        if (monthlyRate === 0) {
            pmt = fv / nper; // If rate is 0, payment is future value divided by periods
        } else if (nper === 0) {
            pmt = fv; // If number of periods is 0, payment is the future value
        } else {
            // Formula to calculate payment using future value, rate, and number of periods
            pmt = fv / ((1 + monthlyRate * type) * ((Math.pow(1 + monthlyRate, nper) - 1) / monthlyRate));
        }
        return pmt; // Return the calculated payment
    };

    // State variables for the calculator inputs
    const [currentAge, setCurrentAge] = useState(30); // Current age of the user
    const [desiredAge, setDesiredAge] = useState(60); // Desired retirement age
    const [lifeExpectancy, setLifeExpectancy] = useState(85); // Expected life expectancy
    const [currentMonthlyExpenses, setCurrentMonthlyExpenses] = useState(100000); // Current monthly expenses
    const [currentInflationRate, setCurrentInflationRate] = useState(6); // Expected inflation rate
    const [returnPreRetirement, setReturnPreRetirement] = useState(12); // Expected return before retirement
    const [returnPostRetirement, setReturnPostRetirement] = useState(8); // Expected return after retirement
    const [existingRetirementFund, setExistingRetirementFund] = useState(0); // Existing retirement corpus

    // State variables to store the calculated results
    const [futureMonthlyExpenses, setFutureMonthlyExpenses] = useState(0); // Future monthly expenses at retirement
    const [corpusRequired, setCorpusRequired] = useState(0); // Total corpus required at retirement
    const [monthlySavingsRequired, setMonthlySavingsRequired] = useState(0); // Monthly savings required to meet retirement goals
    const [additionalCorpusRequired, setAdditionalCorpusRequired] = useState(0); // Additional Corpus required to meet Total Lumpsum Retirement Corpus
    const [afterRetirementFlowType, setAfterRetirementFlowType] = useState('graph');
    const chartLabel = ['Remaining Corpus', 'Yearly Expense'];
    const tooltipLabel = ['Expenses', 'Corpus'];
    // Calculate years to retirement
    const yearsToRetirement = desiredAge - currentAge;
    // Calculate the duration of retirement in years
    const retirementDuration = lifeExpectancy - desiredAge;

    // Function to calculate data for graph and table
    const calculateGraphTable = (inflationRate, initialCorpus, monthlyExpense, investmentGrowth, years) => {
      const inflationRateMonthly = inflationRate / 100 / 12; // Convert annual inflation rate to monthly
      const investmentGrowthMonthly = investmentGrowth / 100 / 12; // Convert annual growth rate to monthly
      const yearlyData = []; // Array to store year-wise data
      
      // Array of month names to display in the graph/table
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
  
      let corpus = initialCorpus; // Initial value of the corpus
      let currentYear = new Date().getFullYear() + yearsToRetirement; // Automatically set the start year to the current year
      
      // Loop through each month of the retirement duration
      for (let month = 0; corpus > 0 && month < years * 12; month++) {
          const currentMonthExpense = monthlyExpense * Math.pow(1 + inflationRateMonthly, month); // Adjust expenses for inflation
          corpus -= currentMonthExpense; // Subtract monthly expenses from the corpus
          corpus *= (1 + investmentGrowthMonthly); // Increase corpus by investment growth
          
          const year = Math.floor(month / 12); // Calculate the current year in the loop
          const monthInYear = month % 12; // Get the month within the year
          
          if (!yearlyData[year]) {
              // Initialize data for a new year
              yearlyData[year] = { year: currentYear + year, months: [] };
          }
          yearlyData[year].months.push({
              yearNo: year + 1,
              yearName: currentYear + year, // Add year name
              month: monthNames[monthInYear], // Use month name instead of number
              expense: currentMonthExpense.toFixed(0), // Expense for the current month
              remainingCorpus: corpus.toFixed(0) // Remaining corpus after expenses
          });
  
          if (corpus <= 0) break; // Stop if corpus is exhausted
      }
      return yearlyData; // Return the generated data
    };
    // Function to calculate future monthly expenses at retirement
    const calculateFutureMonthlyExpenses = () => {
        const monthsToRetire = yearsToRetirement * 12; // Total months to retirement
        return Math.abs(calculateFV(currentInflationRate, monthsToRetire, 0, currentMonthlyExpenses, 1)); // Calculate the future value of monthly expenses
    };

    // Function to calculate the value of the current lump sum at retirement
    const valueOfCurrentLumpsumAtRetirement = () => {
        const monthsToRetire = yearsToRetirement * 12; // Total months to retirement
        return Math.abs(calculateFV(returnPreRetirement, monthsToRetire, 0, existingRetirementFund, 0)); // Calculate the future value of the existing fund
    };

    // Function to calculate the total corpus required at retirement
    const calculateCorpusRequired = () => {
        const retirementMonths = retirementDuration * 12; // Total months of retirement duration
        const futureMonthlyExpenses = calculateFutureMonthlyExpenses(); // Get future monthly expenses

        // Special case handling when returnPostRetirement equals currentInflationRate
        if (returnPostRetirement === currentInflationRate) {
          return Math.abs(futureMonthlyExpenses * retirementMonths); // Corpus is simply future expenses for all retirement months
        }
        return Math.abs(calculatePV(returnPostRetirement - currentInflationRate, retirementMonths, -futureMonthlyExpenses, 0, 1)); // Calculate the present value of the corpus required
    };

    // Function to calculate the monthly savings required to meet the retirement corpus goal
    const calculateMonthlySavingsRequired = () => {
      const totalCorpusNeeded = calculateCorpusRequired(); // Total corpus needed at retirement
      const currentCorpus = valueOfCurrentLumpsumAtRetirement(); // Current value of investments at retirement
      const additionalFundRequired = totalCorpusNeeded - currentCorpus; // Additional funds required to meet corpus goal
      
      // If no additional funds are needed, return 0
      if (additionalFundRequired <= 0) {
          return 0;
      }
  
      const monthsUntilRetirement = yearsToRetirement * 12; // Convert years to months
      const monthlySavingsRequired = Math.abs(
          calculatePMT(returnPreRetirement, monthsUntilRetirement, additionalFundRequired, 1)
      ); // Calculate monthly savings needed
  
      return monthlySavingsRequired; // Return the monthly savings required
    };
  
    const calculateAdditionalCorpusToMeetLumpsum = () => {
      const additionalCorpusRequired = Math.max(0, calculateCorpusRequired() - valueOfCurrentLumpsumAtRetirement());
      return additionalCorpusRequired;
    }
    // Generate the year-wise and month-wise data for the graph and table
    const data = calculateGraphTable(currentInflationRate, calculateCorpusRequired(), calculateFutureMonthlyExpenses(), returnPostRetirement, retirementDuration);
    
    const calculatePieChartData = () => {
      // Convert the annual rate to a monthly rate
      const monthsToRetirement = yearsToRetirement * 12; // Total months to retirement

      const amountRequired = calculateCorpusRequired();
      const futureValueOfMonthlySavings = calculateMonthlySavingsRequired() * monthsToRetirement;
      const principalAmount = (existingRetirementFund > amountRequired) ? existingRetirementFund : existingRetirementFund + futureValueOfMonthlySavings
      // Growth Amount = Future Value after retirement - Principal Amount
      const growthAmount = Math.max(0, amountRequired - principalAmount);
      // Return the results
      return [
        { label: "Principal Amount", count: Math.round(principalAmount) },
        { label: "Interest Amount", count: Math.round(growthAmount) }
      ];
    };
    
    const pieChartData = calculatePieChartData();
    useEffect(() => {
        setFutureMonthlyExpenses(calculateFutureMonthlyExpenses()); // Update future monthly expenses
        setCorpusRequired(calculateCorpusRequired()); // Update corpus required
        setMonthlySavingsRequired(calculateMonthlySavingsRequired()); // Update monthly savings required
        setAdditionalCorpusRequired(calculateAdditionalCorpusToMeetLumpsum());
    }, [
        currentAge,
        desiredAge,
        lifeExpectancy,
        currentMonthlyExpenses,
        currentInflationRate,
        returnPreRetirement,
        returnPostRetirement,
        existingRetirementFund
    ]);

    const handleTabSelect = (type) => {
      setAfterRetirementFlowType(type);
    };
    return (
        <>
            {isAuthenticated ? <Header /> : <HeaderNoValidation />}
            
            <div className='retirement-container container'>
                <h1 className='retirement-heading'>Retirement Planning Calculator</h1>
                <div className='retirement-row'>
                    <div className='retirement-left'>
                        <SliderWithInput onChange={setCurrentAge} label={'Current Age'} inputLabel={'Years'} defaultValue={currentAge} min={0} max={100} allowDecimal={true}/>
                        <SliderWithInput onChange={setDesiredAge} label={'Desired Retirement Age'} inputLabel={'Years'} defaultValue={desiredAge} min={0} max={100} allowDecimal={true}/>
                        <SliderWithInput onChange={setLifeExpectancy} label={'Life Expectancy'} inputLabel={'Years'} defaultValue={lifeExpectancy} min={0} max={100} allowDecimal={true}/>
                        <SliderWithInput onChange={setCurrentMonthlyExpenses} label={'Current Monthly Expenses'} inputLabel={'₹'} defaultValue={currentMonthlyExpenses} min={0} max={1000000} step={1000} />
                        <SliderWithInput onChange={setCurrentInflationRate} label={'Expected Inflation Rate'} inputLabel={'%'} defaultValue={currentInflationRate} min={0} max={20} allowDecimal={true}/>
                        <SliderWithInput onChange={setReturnPreRetirement} label={'Expected Return On Investment (Pre-retirement)'} inputLabel={'%'} defaultValue={returnPreRetirement} min={0} max={20} allowDecimal={true}/>
                        <SliderWithInput onChange={setReturnPostRetirement} label={'Expected Return On Investment (Post-retirement)'} inputLabel={'%'} defaultValue={returnPostRetirement} min={0} max={20} allowDecimal={true}/>
                        <SliderWithInput onChange={setExistingRetirementFund} label={'Existing Retirement Fund'} inputLabel={'₹'} defaultValue={existingRetirementFund} min={0} max={100000000} step={10000}/>
                    </div>
                    <div className='right-border retirement'></div>
                    <div className='retirement-right'>
                        <CalculatorPieChart 
                            pieChartData = {pieChartData}
                        />
                        <div>
                            <div className='right-value-container'>
                              <span className='value-title'>Monthly Expenses at Retirement:</span> 
                              <span className='right-value'>₹ {formatRupees(futureMonthlyExpenses.toFixed(0))}</span>
                            </div>
                            <div className='right-value-container'>
                              <span className='value-title'>Total Lumpsum Retirement Corpus Required:</span>
                              <span className='right-value'>₹ {formatRupees(corpusRequired.toFixed(0))}</span>
                            </div>
                            <div className='right-value-container'>
                              <span className='value-title'>Additional Corpus Required to meet Total Retirement Lumpsum:</span>
                              <span className='right-value'>₹ {additionalCorpusRequired >= 0 ? formatRupees(additionalCorpusRequired.toFixed(0)) : '0'}</span>
                            </div>
                            <div className='right-value-container'>
                              <span className='value-title'>Additional Monthly Savings Required to Reach Retirement Goal:</span>
                              <span className='right-value'>₹ {monthlySavingsRequired >= 0 ? formatRupees(monthlySavingsRequired.toFixed(0)) : '0.00'}</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='retirement-row'>
                <div className='show-table-graph-container'>
                  <h4 className='graph-heading'>After Retirement</h4>
                  <div className="toggle-button-group">
                    <button
                          className={`toggle-button ${afterRetirementFlowType === 'graph' ? 'active' : ''}`}
                          onClick={() => handleTabSelect('graph')}
                      >
                          Graph
                    </button>
                      <button
                          className={`toggle-button ${afterRetirementFlowType === 'table' ? 'active' : ''}`}
                          onClick={() => handleTabSelect('table')}
                      >
                          Table
                      </button>
                  </div>
                  {/* Conditional Rendering based on selected tab */}
                  {afterRetirementFlowType === 'table' ? (
                      <CalculatorTable data={data} module={'retirement'}/>
                  ) : (
                      <CalculatorGraph data={data} chartLabel={chartLabel} tooltipLabel={tooltipLabel} module={'retirement'}/>
                  )}
                </div>
                </div>
            </div>
        </>
    );
};

export default RetirementCalculator;
