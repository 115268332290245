import React from "react";
import '../css/CompanyInformation.css';

function AnalystRecommendation ({ data }) {
    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row">
                <h4 className="heading">Analyst Recommendation <span className={`status ${data.analyst_recommendation.toLowerCase()}`}>{data.analyst_recommendation}</span></h4>
                <div className="scheme-info-details-value-main-column">
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">
                                {data?.target_high_price ?? "N/A"}
                            </span>
                            <span className="scheme-info-details-subheading">Target High</span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-rest-row-value mg-tp-3">{data?.target_low_price ?? "N/A"}</span>
                            <span className="scheme-info-details-subheading">Target Low</span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-rest-row-value mg-tp-3">{data?.num_analyst_opinions ?? "N/A"}</span>
                            <span className="scheme-info-details-subheading">Num Analyst</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalystRecommendation;
