import React from "react";
import '../css/StockCell.css';
import classNames from 'classnames';
import { isNegative, removeSign, shortFormatRupees } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';

function StockCell ({data, module}) {
    const navigate = useNavigate();

    const handleNavigation = (id) => {
        navigate(`/assets/${module}/information/${id}`);
    };

    const returns = data.returns;
    const returnsPercentage = isNegative(parseInt(returns[0]));
    const profitLossSymbol = (returnsPercentage) ? '' : '+';
    const profitLossClass = (returnsPercentage) ? 'loss-color' : 'profit-color';
    
    return (
        <div className="broker-cell-container" key={data.id} onClick={() => handleNavigation(module === 'mutualfund' ? data.scheme_code : data.id)}>
            <div className="broker-cell-row">
                <div className="broker-cell-icon">
                    <img alt="" loading="lazy" width="32" height="32" decoding="async" data-nimg="1" src={data.logo} style={{color: 'transparent'}} onError={(e) => {e.target.onerror = null; e.target.style.display = 'none'; }}/>
                </div>
                <div className="broker-cell-name-container">
                    <p className="broker-cell-name" style={{textAlign: 'unset'}}>{data.name}</p>
                </div>
                <div>
                    <div className="broker-cell-price-container">
                        <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="broker-cell-right-icon">
                            <path d="M5.57 5.226l3.143 2.778-3.142 2.777a.656.656 0 000 1.01c.316.279.826.279 1.142 0l3.717-3.286a.656.656 0 000-1.01L6.713 4.21a.883.883 0 00-1.142 0 .67.67 0 000 1.017z" fill="currentColor"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="broker-cell-row padding-unset">
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Investment</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(data.investment)}</p>
                </div>
                {module !== 'mutualfund' ? (
                    <div className="broker-cell-name-container broker-cell-details">
                        <p className="broker-cell-name broker-name-color">Qty</p>
                        <p className="broker-cell-account">{data.quantity}</p>
                    </div>
                ) : (
                    // Render nothing or an alternative component when the condition is not met
                    null
                )}
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color min-res-420">Current Value</p>
                    <p className="broker-cell-name broker-name-color max-res-420">Curr Val</p>
                    <p className="broker-cell-account">₹{shortFormatRupees(data.curr_val)}</p>
                </div>
                <div className="broker-cell-name-container broker-cell-details">
                    <p className="broker-cell-name broker-name-color">Returns</p>
                    <p className={classNames('broker-cell-account returns-col', profitLossClass)}><span>₹{shortFormatRupees(returns[0])}</span> <span>({profitLossSymbol}{removeSign(returns[1])}%)</span></p>
                </div>
            </div>
        </div>
    );
}

export default StockCell;