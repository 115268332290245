// Import necessary modules and components from React and Bootstrap
import React, { useState, useEffect } from 'react';
import '../css/RealEstate.css'; // Import custom CSS styles
import { Modal, Button, Form } from 'react-bootstrap'; // Import Modal, Button, and Form components from React Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { apiRequest } from '../utils/helpers';
import { apiConfig } from '../ApiConfig';

// Define the ChangePassword functional component
const ChangePassword = ({ onClose }) => {
  
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // State to manage form validation for each metal
    const [validated, setValidated] = useState(false); // Validation state
    // State to manage dirty form status 
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false); // State to toggle password visibility
    const [showNewPassword, setShowNewPassword] = useState(false); // State to toggle password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State to toggle password visibility

    const [isButtonLoading, setIsButtonLoading] = useState(false); // Loading state for button
    
    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };
    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };
    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    }

    // Handle form submission
    const handleSubmit = async (event) => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        event.preventDefault(); // Prevent default form submission behavior
        const form = event.currentTarget; // Get the form element

        // Check form validity
        if (form.checkValidity() === false || (newPassword && confirmPassword && newPassword !== confirmPassword)) {
            event.stopPropagation(); // Stop submission if form is invalid
            setValidated(true);

            // Only show the error if both fields are filled and passwords do not match
            if (newPassword && confirmPassword) {
                if (newPassword !== confirmPassword) {
                    setError('Passwords do not match.');
                } else {
                    setError(null); // Clear error if passwords match
                }
            } else {
                setError(null); // Clear error if one or both fields are empty
            }
        } else {
            setError(null);
            setSuccess(false);
            setIsButtonLoading(true); // Set button to loading state
            try {
                const formData = {
                    email: userInfo.email,
                    currentPassword: currentPassword,
                    password: confirmPassword
                }
                const result = await apiRequest(apiConfig.UPDATEPROFILE, 'POST', formData);
            
                if (result.statusCode === 200) {
                    setSuccess(true);
                } else if (result.statusCode === 401 || result.statusCode === 400) {
                    setError(result.message);
                }
            } catch (error) {
                // Handle network or other errors
                setError(error.message || 'Something went wrong, please try again.');
            } finally {
                setIsButtonLoading(false); // Set loading state back to false
            }
        }
    };

    // Render the form modal
    return (
        <Modal show={true} onHide={onClose} backdrop="static">
            {/* Modal Header */}
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            {/* Modal Body */}
            <Modal.Body>              
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="currentPassword" className='password-container changePassword'>
                        <Form.Control
                            type={showCurrentPassword ? 'text' : 'password'}
                            placeholder="Current Password *"
                            required
                            autoComplete='off'
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                         <i
                            className={`password-eye fa ${showCurrentPassword ? 'fa-eye-slash' : 'fa-eye'}`} // Icon changes based on visibility state
                            onClick={toggleShowCurrentPassword}
                            aria-hidden="true"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the current password.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="newPassword" className='password-container changePassword'>
                        <Form.Control
                            type={showNewPassword ? 'text' : 'password'}
                            placeholder="New Password *"
                            required
                            autoComplete='off'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                         <i
                            className={`password-eye fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`} // Icon changes based on visibility state
                            onClick={toggleShowNewPassword}
                            aria-hidden="true"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the new password.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="confirmPassword" className='password-container changePassword'>
                        <Form.Control
                            type={showConfirmPassword ? 'text' : 'password'}
                            placeholder="Confirm Password *"
                            required
                            autoComplete='off'
                            className={`${error ? 'otp-error' : ''}`}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <i
                            className={`password-eye fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`} // Icon changes based on visibility state
                            onClick={toggleShowConfirmPassword}
                            aria-hidden="true"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter the confirm password.
                        </Form.Control.Feedback>
                    </Form.Group>
                    {success && <div className="success-message">Your password has been updated successfully.</div>}
                    {error && <div className="error-message">{error}</div>}
                    <div className="btn-container">
                        <Button type='submit' variant="primary" disabled={isButtonLoading}>
                        {   isButtonLoading ? 'Updating...' : 'Update' }
                        </Button>
                    </div>
                </Form>
                
                {/* Form with validation */}
            </Modal.Body>
        </Modal>
    );
};

// Export the ChangePassword component as the default export
export default ChangePassword;
