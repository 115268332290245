import React, { useState } from "react";
import { formatDate, apiRequest } from "../../utils/helpers";
import PrivateEquityForm from "./PrivateEquityForm";
import { apiConfig } from "../../ApiConfig";
import { message } from "antd";

function PrivateEquityTransactionList({ information, transaction, handleRefresh }) {
    const [showModal, setShowModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    
    const handleEdit = (transaction) => {

        setCurrentTransaction({
            ...transaction,
            name: information.name,
        });
        setShowModal(true);
    };

    // Handle modal close
    const handleDelete = async (id) => {
        const confirmClose = window.confirm('Are you sure you want to delete?');
        if (!confirmClose) {
            return; // Prevent closing if user cancels
        }
        try {
            const url = `${apiConfig.PRIVATEEQUITYTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'DELETE', null);
            if(result.statusCode === 200){
                handleRefresh();
                handleCloseModal(); // Close the modal after submission
            }else {
                message.error({
                    content: `Failed to update form: ${result.message}`,
                    duration: 3, // Duration in seconds
                });
            }
        } catch (error) {
            message.error({
                content: `Failed to update form: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleFormSubmit = async (formData) => {
        const { id, ...dataToSubmit } = formData[0];
        try {
            const url = `${apiConfig.PRIVATEEQUITYTRANSACTIONS}/${id}`;
            // Call the API to post the form data
            const result = await apiRequest(url, 'PUT', dataToSubmit);
            if(result.statusCode === 200){
                handleRefresh();
                handleCloseModal(); // Close the modal after submission
            }else {
                message.error({
                    content: `Failed to update form: ${result.message}`,
                    duration: 3, // Duration in seconds
                });
            }
        } catch (error) {
            message.error({
                content: `Failed to update form: ${error.message}`,
                duration: 3, // Duration in seconds
            });
        }
    };

    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row table-responsive" style={{backgroundColor: 'unset'}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Investment</th>
                            <th scope="col">Qty</th>
                            <th scope="col" className="min-res-420">Price Per Share</th>
                            <th scope="col">Date</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transaction.length > 0 ? (
                            transaction.map(details => (
                                <tr key={details.id}>
                                    <td>{information.name}</td>
                                    <td>₹{information.investment}</td>
                                    <td>{details.quantity}</td>
                                    <td>₹{details.stockprice}</td>
                                    <td>{formatDate(parseInt(details.date))}</td>
                                    <td>
                                        <i className="fas fa-edit edit-company" onClick={(e) => { e.preventDefault(); handleEdit(details); }}></i>
                                        <i className="fas fa-trash delete-company" onClick={(e) => { e.preventDefault(); handleDelete(details.id); }}></i>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No transactions available</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {/* Render the modal form */}
            {showModal && (
                <PrivateEquityForm
                    isOpen={showModal}
                    closeModal={handleCloseModal}
                    onFormSubmit={handleFormSubmit}
                    initialFormData={currentTransaction}
                    onEdit={true}
                />
            )}
        </div>
    );
}

export default PrivateEquityTransactionList;
