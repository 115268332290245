import React, {useState} from 'react';
import Sidebar from './navbar/Sidebar';
import Navbar from './navbar/Navbar';
import '../css/Header.css';
import '../css/Sidebar.css';
import { useAuth } from "../AuthContext";

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { isAuthenticated} = useAuth();  // Authentication status

    const toggleSidebar = () => {
        setIsOpen(prevState => !prevState);
    };

    return (
        <div className="container-fluid pad-un">
            {isAuthenticated ? 
            <>
                <Navbar toggleSidebar={toggleSidebar}/>
                <div className={`sidebar ${isOpen ? 'active' : ''}`}>
                    <Sidebar toggleSidebar={toggleSidebar}/>
                </div>
                <div className={`sidebar-overlay ${isOpen ? 'active' : ''}`} onClick={toggleSidebar}></div>
            </>
            : 
           ""
            }
        </div>
    );
};

export default Header;