import React from "react";
import '../../css/CompanyInformation.css';
import { formatDateWithTime, shortFormatRupees, threeDigitAfterDecimal } from "../../utils/helpers";

function IndiaStockCompanyInformation ({ info, insights }) {
    return (
        <div className="scheme-info-details-container">
            <div className="scheme-info-details-row">
                <h4 className="heading">Company Information</h4>
                <div className="scheme-info-details-heading-container">
                    <div className="scheme-info-details-heading-img-container">
                        {info.logo && (
                            <img 
                                alt={`${info.name} logo`} 
                                loading="lazy" 
                                width="40" 
                                height="40" 
                                decoding="async" 
                                src={info.logo} 
                                style={{color: 'transparent'}} 
                                onError={(e) => {
                                    e.target.onerror = null; // Prevents infinite loop in case the fallback also fails
                                    e.target.style.display = 'none'; // Removes image space when the image fails to load
                                }}
                            />
                        )}
                    </div>
                    <div className="scheme-info-details-heading-name-container">
                        <h6 className="heading">{info.name}</h6>
                        <div className="scheme-info-details-subheading">
                            <span>as of {formatDateWithTime(insights.date)}</span> {/* Consider making this dynamic */}
                        </div>
                    </div>
                </div>
                <div className="scheme-info-details-value-main-column">
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">
                                {insights?.bse_code ?? "N/A"}
                            </span>
                            <span className="scheme-info-details-subheading">BSE Code</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Exchange</span>
                            <span className="scheme-rest-row-value mg-tp-3">{insights?.exchange ?? "N/A"}</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Stock Type</span>
                            <span className="scheme-rest-row-value mg-tp-3">{insights?.type ?? "N/A"}</span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">{insights?.isin ?? "N/A"}</span>
                            <span className="scheme-info-details-subheading">ISIN</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Industry</span>
                            <span className="scheme-rest-row-value mg-tp-3">{insights?.industry ?? "N/A"}</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Listing Date</span>
                            <span className="scheme-rest-row-value mg-tp-3">
                                {insights?.listing_date ?? "N/A"}
                            </span>
                        </div>
                    </div>
                    <div className="scheme-info-details-value-column">
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-first-row-value">{insights?.sector ?? "N/A"}</span>
                            <span className="scheme-info-details-subheading">Sector</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            <span className="scheme-info-details-subheading">Market Cap</span>
                            <span className="scheme-rest-row-value mg-tp-3">{insights?.market_cap ? `₹${shortFormatRupees(insights.market_cap)}` : "N/A"}</span>
                        </div>
                        <div className="scheme-info-details-value-container">
                            {/* <span className="scheme-info-details-subheading">Employees</span>
                            <span className="scheme-rest-row-value mg-tp-3">{insights?.employees ?? "N/A"}</span> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndiaStockCompanyInformation;
