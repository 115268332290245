import React from 'react';
import '../css/EmptyState.css'
import { getFullUrl } from '../utils/helpers';
import { useNavigate } from 'react-router-dom';

function EmptyState({ openModal = false }) {
    const navigate = useNavigate();
    return (
        <div className="empty-state-container">
            <img src={getFullUrl('images/emptystate.png')} alt="No data available" className="empty-state-image" />
            <p className="empty-state-text">Add your assets to explore insights and performance!</p>
            <a onClick={() => navigate('/assets')} className="back-btn">Back to Home</a>
            {openModal ? 
                <div className="float-container">
                    <a className="float"  onClick={(e) => { e.preventDefault(); openModal() }}>
                        <i className="fa fa-plus my-float"></i>
                    </a>
                </div>
            : ''
            }
        </div>
    );
}

export default EmptyState;
